@import "../../assets/css/common";
.andTracking {
  @include boxSize(100%, auto);
  overflow: hidden;
  position: relative;
  margin-top: -.6rem;
  .andTrackingHeader {
    @include boxSize(100%, 4.8rem);
    @include backgroundImageSetting("../../assets/images/andTracking/andTrackingBG.png");
    position: relative;
    padding: 1.82rem .1rem 0;
    .subHeaderRoot {
      @include boxSize(100%, autbusinessStructureo);
      text-align: center;
      .andTrackingName {
        @include fontStyle(#ffffff, .48rem);
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 1px;
      }
      .andTrackingDescribe {
        @include fontStyle(#ffffff, .24rem);
        letter-spacing: 1px;
        margin-bottom: .5rem;
      }
      button{
        @include fontStyle(#ffffff, .18rem);
        width: 1.34rem;
        margin: 0;
        padding: 0;
      }
      .consultButton {
        border: 1px solid #ffffff;
        border-radius: 2px;
        background-color: transparent;
        margin-left: .2rem;
      }
    }
  }
  .andTracking-content{
    .firstSection{
      @include boxSize(100%, auto);
      background: #ffffff;
      padding: .6rem 0 1.2rem;
      .headerTitle {
        @include fontStyle(#000000, .34rem);
        font-weight: 500;
        line-height: .48rem;
        padding-bottom: .6rem;
        text-align: center;
      }
      .supervisionSystem{
        display: flex;
        justify-content: center;
        .describe{
          margin-left: .8rem;
          @include boxSize(5.2rem, 3.1rem);
          .text{
            padding: .24rem .1rem .5rem .2rem;
            font-size: 16px;
            text-align: justify;
            color: rgba(0,0,0,0.85);
            line-height: .28rem;
            letter-spacing: .5px;
            background: url(../../assets/images/andTracking/bgPlate.png) no-repeat 0 100%;
          }
          .quotationMarkRight{
            float: right;
            margin-top: -.43rem;
          }
        }
      }
    }
    .headerTitle {
      @include fontStyle(#000000, .34rem);
      font-weight: 500;
      line-height: .48rem;
    }
    .secondSection{
      @include boxSize(100%, auto);
      padding-top: .6rem;
      padding-bottom: 1.21rem;
      text-align: center;
      .headerTitle {
        @include boxSize(100%, .48rem);
        @include flexCenter();
        @include fontStyle(#000000, .34rem);
        font-weight: 500;
      }
      .businessStructure{
        margin-top: .6rem;
        width: 10.49rem;
        height: 473px;
        object-fit: contain;
      }
    }
    .threeSection{
      @include boxSize(100%, auto);
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 1.2rem;
      padding-top: .6rem;
    }
  }
}
