@import "../../assets/css/common";

.commonHeader {
    @include boxSize(100%, 0.6rem);
    @include flexCenter();
    z-index: 2;
    position: relative;
    //background-color: rgba(2, 22, 55, .4);
    color: white;
    background-color: rgba(0, 0, 0, 0.1);

    .headerContent {
        width: 100%;
        // max-width: 1160px;
        max-width: 1000px;

        .headerMenuName {
            color: #ffffff;
        }

        .commonHeaderLogo {
            @include boxSize(1.2rem, 0.31rem);
            // @include leftTop(.4rem, 50%);
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            float: left;
            background-image: url(../../assets/images/common/logo@2x.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        .ant-menu-item-selected {
            border-bottom: 2px solid #1890ff !important;
        }

        .ant-menu-horizontal {
            border-bottom: none;
        }

        .ant-menu-horizontal > .ant-menu-item,
        .ant-menu-horizontal > .ant-menu-submenu {
            top: -1px;
        }

        .ant-menu-item:hover,
        .ant-menu-item-active {
            border-bottom: 2px solid transparent !important;
        }
        .ant-menu-item-selected,
        .ant-menu-item-selected:hover {
            border-bottom: 2px solid transparent !important;
        }

        .ant-menu-submenu:hover,
        .ant-menu-submenu-active {
            border-bottom: 2px solid transparent !important;
        }

        .ant-menu-submenu-selected,
        .ant-menu-submenu-selected:hover {
            border-bottom: 2px solid transparent !important;
        }
    }
    .scrmBtn {
        width: 120px;
        height: 38px;
        background: rgba(24, 144, 255, 0.9);
        border-radius: 6px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        line-height: 38px;
        cursor: pointer;
    }
}

.ant-menu-item-selected {
    background: transparent !important;
}

.ant-menu-submenu > .ant-menu {
    background: rgba(3, 13, 48, 0.8) !important;
}

.ant-menu {
    .ant-menu-item-group {
        display: inline-block;
        vertical-align: top;

        .ant-menu-item-group-title {
            color: #ffffff;
        }
        .ant-menu-item-group-list {
            .ant-menu-item {
                padding: 8px 16px;
            }
        }
    }

    .headerMenuName {
        color: #ffffff;
    }
}
