@import "../../assets/css/common";
.mobileAndFinance {
  margin-top: 0;
  .andFinanceHeader {
    height: 6.66rem;
    padding: 1rem .6rem 0;
    .subHeaderRoot {
      .andFinanceName {
        white-space: pre-wrap;
        line-height: 1.2;
        margin-bottom: .48rem;
      }
      .andFinanceDescribe {
        font-size: .32rem;
        margin-bottom: .76rem;
        letter-spacing: 0.5px;
      }
      button{
        font-size: .3rem;
        height: .82rem !important;
        width: 2.82rem;
      }
    }
  }

  .andFinance-content{
    .firstSection{
      padding: 1rem .4rem 1.4rem;
      .headerTitle {
        height: auto;
        font-size: .48rem;
        margin-bottom: .24rem;
      }
      .subTitle {
        font-size: .28rem;
        margin-top: 0;
        margin-bottom: .8rem;
        text-align: left;
      }
      .painSpotImg{
        padding: 0 .2rem;
        margin-top: .6rem;
        width: 100%;
        object-fit: contain;
        img{
          width: 100%;
        }
      }
    }
    .headerTitle {
      font-size: .48rem;
    }
    .threeSection{
      height: auto;
      padding: 1rem 0 1.4rem;
    }
  }
}
