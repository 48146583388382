@import "../../assets/css/common";
.mobileBottom {
    @include boxSize(100%, auto);
    background-image: url("../../assets/images/common/mobileBottomBg.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    padding: 1rem .4rem 1.77rem;
    position: relative;
    .mobileBottomLogo {
        height: .65rem;
        margin-bottom: .56rem;
    }
    .mobileBottomMenuRoot {
        display: flex;
        @include fontStyle(#ffffff, .34rem);
        margin-bottom: .64rem;
        margin-left: -.28rem;
        .mobileBottomMenuItem {
            @include fontStyle(#ffffff, .34rem);
            font-weight: 600;
            margin: 0 .28rem;
        }
    }
    .mobileBottomSubMenuRoot {
        @include boxSize(100%, auto);
        .mobileBottomSubMenuTitle {
            @include boxSize(100%, .93rem);
            @include fontStyle(#ffffff, .32rem);
            @include flexAlignCenter();
            justify-content: space-between;
            border-bottom: 1px solid rgba(255,255,255,.2);
        }
        .mobileBottomSubMenu {
            @include boxSize(100%, 0);
            overflow: hidden;
            padding: 0;
            .mobileBottomSubMenuItem {
                @include fontStyle(#ffffff, .28rem);
                margin-bottom: .3rem;
            }
        }
        .mobileBottomSubMenuActive {
            height: auto;
            padding: .4rem 0 .1rem;
            border-bottom: 1px solid rgba(255,255,255,.2);
        }
    }
    .mobileBottomBottom {
        @include boxSize(100%, auto);
        @include fontStyle(#ffffff, .28rem);
        margin-top: .8rem;
        display: flex;
        justify-content: space-between;
        .mobileBottomBottomLeft {
            @include boxSize(2.4rem, auto);
            text-align: center;
            img{
                @include boxSize(2.4rem, 2.4rem);
                margin-bottom: .2rem;
            }
        }
        .mobileBottomBottomRight {
            @include boxSize(calc(100% - 2.9rem), auto);
            display: flex;
            flex-direction: column;
            white-space: pre-wrap;
            &>span{
                display: flex;
                margin-bottom: .3rem;
                &>span:nth-child(1){
                    flex: none;
                }
            }
        }
    }
    .mobileRecordNo {
        @include boxSize(100%, .77rem);
        @include leftBottom(0, 0);
        @include fontStyle(#74798c, .26rem);
        @include flexCenter();
        position: absolute;
        background-color: rgba(0,0,0,.4);
        white-space: pre-wrap;
    }
}
