.commonFixedButton {
    .helpButton {
        position: fixed;
        // right: 0.17rem;
        // bottom: 1rem;
        right: 20px;
        bottom: 25px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        background: #347eff;
        border-radius: 6px;
        // color: #ffffff;
        // font-size: 22px;
        line-height: 50px;
        text-align: center;
        z-index: 100;
        .helpButtonEar{
            width: 22px;
            height: 22px;
        }
    }
    .helpContent {
        //position: fixed;
        width: 200px;
        height: 60px;
        background: #ffffff;
        //box-shadow: 0px 0px 14px 0px rgba(2, 25, 102, 0.2);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 5px;
        position: relative;
    
        .helpTitle {
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #74798c;
            line-height: 20px;
            letter-spacing: 0px;
        }
    
        .helpType {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
    
            .inconStyle {
                width: 22px;
                height: 18px;
                color: #347eff;
            }
        }
    
        .tips {
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            line-height: 20px;
            width: 100%;
            .telephoneNumber {
                color: #347eff;
            }
        }
    
        .telephone{
            display: flex;
            justify-content: space-between;
        }
    
        .closeCircleOutlined{
            position: absolute;
            left: -40px;
            top: -10px;
            width: 20px;
            height: 20px;
            background: transparent;
            cursor: pointer;
        }
    
    }
    
    .onLineContent{
        width: 210px;
        height: 140px;
    }

    .cursorPointer {
        cursor: pointer;
    }
}

