.commonBehavior {
    display: flex;
    justify-content: space-between;
    // padding-top: 60px;
    width: 100%;
    max-width: 1160px;
    flex-wrap: wrap;
    padding-bottom: 0.6rem;

    .behaviorBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0.6rem;
        height: 300px;

        .behaviorContent {
            background: #ffffff;
            border-radius: 4px;
            box-shadow: 0px 2px 10px 0px rgba(10, 11, 28, 0.1);
            padding: 30px;
            margin-top: 25px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 300px;

            .topImg {
                width: 41px;
                height: 41px;
                object-fit: contain;
            }
            .titleName {
                font-size: 18px;
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                text-align: center;
                color: rgba(0, 0, 0, 0.85);
                line-height: 22px;
                padding: 20px 0 10px 0;
            }

            .line {
                width: 25px;
                height: 3px;
                background: #74bcff;
                margin: 0 auto;
            }

            .titleContent {
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                text-align: justify;
                color: rgba(0, 0, 0, 0.85);
                line-height: 22px;
                letter-spacing: 0px;
                line-height: 22px;
                padding-top: 15px;
            }
        }
    }
}