@import "../../assets/css/common";

.andChain {
    @include boxSize(100%, auto);
    margin-top: -.6rem;
    overflow: hidden;
    position: relative;

    .andChainSubHeader {
        @include boxSize(100%, 4.8rem);
        @include backgroundImageSetting("../../assets/images/andChain/banner.png");
        position: relative;
        padding-top: 1.82rem;

        .subHeaderRoot {
            @include boxSize(100%, auto);
            text-align: center;

            .compassProductName {
                @include fontStyle(#ffffff, .48rem);
                font-weight: 600;
                margin-bottom: 1px;
            }

            .compassProductDescribe {
                @include fontStyle(#ffffff, .24rem);
                letter-spacing: 1px;
                margin-bottom: .5rem;
            }

            button {
                @include fontStyle(#ffffff, .18rem);
                width: 1.34rem;
                margin: 0;
                padding: 0;
            }

            .consultButton {
                border-radius: 2px;
                //margin-left: .2rem;
                background: #347eff;
                color: #ffffff;
                font-size: 0.18rem;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                border: none;
            }
        }
    }

    // margin-top: 60px;
    .box-content {
        .firstSection {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            margin-top: 0.6rem;
            margin-bottom: 1rem;
            .headerTitle {
                font-size: 34px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #000000;
                line-height: 48px;
            }

            .subTitle {
                font-size: 16px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: #74798c;
                line-height: 40px;
                text-align: center;
            }

            .programImg {
                padding: 60px 0px;
            }

            .boxContent {
                display: flex;
                // width: 70%;
                justify-content: space-between;
                // justify-content: center;
                padding-top: 60px;
                width: 100%;
                max-width: 1160px;

                .box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 50px;

                    .topImg {
                        width: 180px;
                        height: 180px;
                    }
                }
            }

            .privateContent {
                flex-wrap: wrap;
                .privateBox {
                    width: 48%;
                    height: 150px;
                    background: url("../../assets/images/andChain/white.png") no-repeat;
                    background-size: 100% 100%;
                    line-height: 22px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    padding-left: 0.4rem;
                    margin-bottom: 40px;
                    border-radius: 4px;

                    &:nth-child(4) {
                        background: url("../../assets/images/andChain/blue.png");
                        background-size: 100% 100%;
                        color: #ffffff;
                        line-height: 22px;

                        .quare {
                            width: 19px;
                            height: 4px;
                            background: #ffffff;
                        }
                        .detail {
                            color: #ffffff;
                        }
                        .titleName {
                            color: #ffffff;
                        }
                    }

                    &:nth-child(1) {
                        background: url("../../assets/images/andChain/blue.png");
                        background-size: 100% 100%;
                        color: #ffffff;

                        .quare {
                            width: 19px;
                            height: 4px;
                            background: #ffffff;
                        }

                        .detail {
                            color: #ffffff;
                        }

                        .titleName {
                            color: #ffffff;
                        }
                    }

                    .titleName {
                        font-size: 16px;
                        font-family: PingFangSC, PingFangSC-Semibold;
                        font-weight: 600;
                        padding-bottom: 10px;
                        color: #242525;
                    }

                    .quare {
                        width: 19px;
                        height: 4px;
                        background: #347eff;
                    }

                    .detail {
                        font-size: 14px;
                        font-family: PingFangSC, PingFangSC-Regular;
                        font-weight: 400;
                        color: #565657;
                    }
                }
            }

            .bottomContent {
                background: url('../../assets/images/andChain/zixun.png') 100% 100% no-repeat;
                width: 100%;
                height: 320px;
                margin-top: -70px;
                color: #ffffff;
                justify-content: center;
                display: flex;
                flex-direction: column;
                align-items: center;

                .infoContent {
                    width: 100%;
                    max-width: 1160px;
                    // display: flex;
                    // justify-content: space-between;

                    .topBigTitle {
                        font-size: 24px;
                        font-family: PingFangSC, PingFangSC-Semibold;
                        font-weight: 600;
                        text-align: left;
                        line-height: 33px;
                    }

                    .bottomSubTitle {
                        font-size: 16px;
                        font-family: PingFangSC, PingFangSC-Regular;
                        font-weight: 400;
                        text-align: left;
                        line-height: 22px;
                        padding-top: 15px;
                        display: flex;
                        justify-content: space-between;
                    }
                }

                .zixun {
                    text-align: center;
                    width: 108px;
                    height: 32px;
                    border: 1px solid #ffffff;
                    border-radius: 2px;
                    line-height: 32px;
                    font-size: 14px;
                    position: absolute;
                    // right: 380px;
                    cursor: pointer;
                    right: calc((100% - 1160px) / 2);
                }
            }
        }

        .secSection {
            background-color: rgba(248, 249, 252, 1);
            padding-top: 60px;
            margin-top: 120px;

            .content {
                position: relative;
                height: 580px;
                width: 100%;
                padding-top: 40px;
                display: flex;

                .leftContent {
                    position: absolute;
                    right: 51%;
                    cursor: pointer;

                    .box {
                        width: 560px;
                        height: 82px;
                        background: #ffffff;
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        padding: 0px 20px;
                        margin-bottom: 24px;

                        &:hover {
                            background: #347eff;
                            box-shadow: 0px 0px 20px 0px rgba(14, 19, 76, 0.2);


                            .leftName {
                                color: #ffffff;

                                .point {
                                    background: #ffffff;
                                }
                            }

                            .rightTitle {
                                color: #ffffff;
                            }
                        }

                        .leftName {
                            font-size: 16px;
                            font-family: PingFangSC, PingFangSC-Semibold;
                            font-weight: 600;
                            text-align: left;
                            color: rgba(0, 0, 0, 0.85);
                            width: 160px;

                            .point {
                                display: inline-block;
                                width: 10px;
                                height: 10px;
                                background: #1890ff;
                                border-radius: 10px;
                                line-height: 20px;
                                margin: 0 20px 0 0;
                            }
                        }

                        .rightTitle {
                            font-size: 14px;
                            font-family: PingFangSC, PingFangSC-Regular;
                            font-weight: 400;
                            text-align: left;
                            color: rgba(0, 0, 0, 0.65);
                            line-height: 22px;
                            width: calc(100% - 160px);
                        }
                    }
                }

                .rightImg {
                    width: 48%;
                    position: absolute;
                    right: 0;
                }

                .rightProgramImg {
                    width: 730px;
                    height: 603px;
                    position: absolute;
                    bottom: 0;
                    right: 130px;
                }
            }
        }

        .forthSection{
            background: #f8f9fc;
            padding: 0.6rem 0 1rem 0;
            margin-bottom: 0;
        }
    }
}
