@import "../../assets/css/common";

.mobileAbout {
    .box-content {
        .title {
            font-size: 18px !important;
            font-weight: 500 !important;
            color: #000000 !important;
        }

        .intr {
            margin-top: 50px;
            padding: 0 20px;

            .contentBox {
                display: block;
                margin-bottom: 70px;

                .intr-content {
                    width: auto !important;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.85);
                    line-height: 20px;
                }
            }

            .imgBox {
                width: auto !important;
                height: auto !important;
                margin-top: 30px;

                .intr-img {
                    img {
                        width: 196px !important;
                        height: 147px !important;
                    }
                }
            }
        }

        .culture {
            height: auto !important;

            .box {
                padding: 0.5rem 0.4rem 0.7rem 0.4rem;
                //height: auto;
                .contentBox {
                    display: block;
                    //   text-align: center;
                    .itemBox {
                        margin-bottom: 30px;

                        .top {
                            width: 100% !important;
                            height: 70px;
                            line-height: 70px;

                            img {
                                width: 25px;
                            }
                        }

                        .bottom {
                            width: 100% !important;
                            padding-bottom: 0.4rem;
                            height: auto;

                            div {
                                &::after {
                                    //left: 42% !important;
                                    left: 50%;
                                    transform: translate(-50%, 0);
                                }
                            }
                        }
                    }
                }
            }
        }

        .partner {
            height: auto !important;
            margin-top: 60px !important;

            .box {
                //padding: 50px 20px 70px 20px;
                padding: 0.5rem 0.4rem 0.7rem 0.4rem;
                .contentBox {
                    img {
                        width: 3.2rem;
                        height: 1.4rem;
                        object-fit: cover;
                        //margin-bottom: .3rem;
                        box-shadow: 0 0 0.1rem 0 rgba(3, 6, 23, 0.1);
                    }
                }
            }
        }

        .qual {
            .box {
                margin: 50px 0 70px 0;
                padding: 0 20px;
            }

            .content {
                div {
                    font-size: 16px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    text-align: left;
                    color: rgba(0, 0, 0, 0.85);
                    line-height: 22px;
                    margin: 0 0 15px 20px;
                    position: relative;

                    &::before {
                        position: absolute;
                        content: "";
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: #337dff;
                        top: 7px;
                        left: -15px;
                    }
                }
            }

            .titleContent{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
        
                .gradeTitle {
                  width: 100%;
                  font-size: rem;
                }
              }
        }

        .contact {
            height: auto;

            .box {
                //padding: 50px 20px 70px 20px;
                padding: 0.5rem 0.4rem 1.4rem 0.4rem;
                height: auto;

                .content {
                    height: auto !important;
                    margin-top: 40px;

                    .top {
                        display: flex;
                        justify-content: space-between;

                        .left {
                            width: 60%;

                            div {
                                font-size: 16px;
                                font-weight: 600;
                                color: #000000;
                                line-height: 22px;
                                margin-bottom: 8px;
                            }

                            p {
                                font-size: 16px;
                                color: #777777;
                                line-height: 22px;
                            }
                        }

                        .right {
                            img {
                                margin-top: 0 !important;
                                width: 105px;
                                height: 105px;
                            }

                            p {
                                margin: 10px 0 0 0 !important;
                                font-size: 16px;
                                color: #777777;
                                line-height: 22px;
                            }
                        }
                    }

                    .bottom {
                        img {
                            width: 335px;
                        }
                    }
                }
            }
        }

        .picture {
            .topBox {
                margin: 60px auto 39px 20px;
            }

            .space-carousel {
                //padding: 16px;
                overflow: hidden;
            }

            .slider {
                height: 270px !important;
            }

            .am-carousel-wrap-dot-active {
                span {
                    background: #337dff;
                }
            }
        }
    }
}
