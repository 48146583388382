@import "../../assets/css/common";
.mobileCommonSolution {
    margin-top: 0;
    .commonSolutionHeader {
        height: 6.18rem;
        padding: 1rem .6rem 0;
        .subHeaderRoot {
            .subHeaderName {
                height: auto;
                font-size: .48rem;
                margin-bottom: .48rem;
            }
            .subHeaderDescribe {
                font-size: .32rem;
                margin-bottom: .76rem;
                letter-spacing: 2px;
            }
            button{
                width: 2.82rem;
                height: .82rem !important;
                font-size: .3rem;
            }
        }
    }
    .commonDivRoot {
        padding: 1rem 0 1.4rem;
        .commonDiv {
            max-width: 100%;
        }
        .commonTitle {
            height: auto;
            font-size: .48rem;
        }
        .commonDescribe {
            padding: 0 .4rem;
            font-size: .28rem;
            margin-top: .8rem;
            letter-spacing: 1px;
        }
        .operationFlowImgRoot{
            padding: 0 .4rem;
            margin-top: .8rem;
        }
        .applicationCasesRoot {
            width: calc(100% - .8rem);
            margin-left: .4rem;
            padding: .6rem .4rem;
            margin-top: .8rem;
            .applicationCasesRootTitle {
                span{
                    font-size: .36rem;
                }
                img{
                    @include boxSize(.44rem, .3rem);
                }
            }
            .commonDivRootText {
                font-size: .28rem;
                padding-left: .16rem;
                letter-spacing: 0.2px;
            }
            .commonIconTitle {
                font-size: .28rem;
                font-weight: 600;
                margin-bottom: .2rem;
                padding-left: .16rem;
                &:before{
                    content: '';
                    @include boxSize(3px, .3rem);
                    @include leftTop(0, 50%);
                    transform: translateY(-50%);
                    position: absolute;
                    background-color: #ffffff;
                }
            }
        }
    }

}
