.showBoxCom {
    display: flex;
    // width: 70%;
    justify-content: space-between;
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;

    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        // margin-right: 50px;
        padding-top: 0.6rem;

        .topImg {
            width: 180px;
            height: 180px;
            object-fit: contain;
        }

        .botContent {
            //width: 240px;
            background-color: #f8f9fc;
            border-radius: 8px;
            padding: 30px;
            margin-top: 25px;

            .titleName {
                font-size: 18px;
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                text-align: center;
                color: #1f4d87;
                line-height: 14px;
                border-bottom: 5px solid rgba(52, 126, 255, 0.2);
                width: 100px;
                margin: 0 auto;
            }

            .titleContent {
                padding-top: 15px;
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                text-align: justify;
                color: rgba(0, 0, 0, 0.85);
                line-height: 22px;
            }
        }
    }
}