@import "../../../assets/css/common";
.mobileApplicationScenarios {
    padding: .8rem 0 2rem;
    .applicationScenarios {
        max-width: 100%;
        .applicationScenariosName {
            font-size: .48rem;
        }
        .applicationScenariosItemRoot {
            height: auto;
            .applicationScenariosItem {
                @include boxSize(100%, 8.6rem);
                background-position: bottom;
                background-color: #ffffff;
                box-shadow: 0 1px .14rem 0 rgba(21,23,94,0.1);
                padding: .2rem .6rem .6rem;
                .applicationScenariosItemImg {
                    @include boxSize(2.8rem, 2.8rem);
                    margin: 0 auto .1rem;
                    img{
                        @include boxSize(2.8rem, 2.8rem);
                        object-fit: contain;
                    }
                }
                .applicationScenariosItemName {
                    height: auto;
                    img{
                        height: .57rem;
                    }
                    .applicationScenariosItemText {
                        height: .57rem;
                        font-size: .3rem;
                        padding: 0 .28rem;
                        margin: 0 6px;
                    }
                }
                .itemDescribeRoot {
                    height: auto;
                    opacity: 1;
                    position: relative;
                    background: transparent;
                    border-radius: 0;
                    box-shadow: none;
                    overflow: hidden;
                    .itemDescribeTitle {
                        height: auto;
                        @include fontStyle(rgba(0,0,0,0.85), .32rem);
                        font-weight: 600;
                        margin-bottom: .2rem;
                        margin-top: 0;
                    }
                    .itemDescribeText {
                        @include fontStyle(rgba(0,0,0,0.85), .28rem);
                        min-height: 3.1rem;
                        padding: 0;
                        margin-bottom: .6rem;
                        letter-spacing: 1px;
                    }
                }
            }
            .slider-decorator-0 {
                bottom: -.7rem !important;
            }
            .am-carousel-wrap-dot > span {
                display: block;
                width: .6rem;
                height: .08rem;
                margin: 0 .1rem;
                border-radius: 0;
                background: rgba(24,144,255,.2);
            }
            .am-carousel-wrap-dot-active > span{
                background-color: #1890ff;
            }
        }
    }
}
