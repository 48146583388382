@import "../../../assets/css/common";
.applicationScenariosRoot {
    @include boxSize(100%, auto);
    position: relative;
    background: #f8f9fc;
    padding: .6rem 0 1.2rem;
    .applicationScenarios {
        @include boxSize(100%, 100%);
        max-width: $maxWidth;
        margin: 0 auto;
        .applicationScenariosName {
            @include fontStyle(#000000, .34rem);
            font-weight: 500;
            text-align: center;
            margin-bottom: .6rem;
        }
        .applicationScenariosItemRoot {
            @include boxSize(100%, 3.18rem);
            display: flex;
            justify-content: space-between;
            .applicationScenariosItem {
                @include boxSize(2.4rem, 3.18rem);
                box-shadow: 0 1px 14px 0 rgba(21,23,94,0.1);
                background-size: 100%, 100%;
                background-repeat: no-repeat;
                border-radius: .08rem;
                overflow: hidden;
                position: relative;
                .applicationScenariosItemImg {
                    @include boxSize(1.4rem, 1.4rem);
                    margin: .6rem auto .58rem;
                    img{
                        object-fit: contain;
                    }
                }
                .applicationScenariosItemName {
                    @include boxSize(100%, .26rem);
                    @include flexCenter();
                    display: flex;
                    .asItemNameImg2 {
                        transform: rotateZ(180deg);
                    }
                    .applicationScenariosItemText {
                        @include flexCenter();
                        @include boxSize(auto, 100%);
                        @include fontStyle(#ffffff, .16rem);
                        padding: 0 .14rem;
                        margin: 0 3px;
                        background: #347eff;
                        border-radius: 1px;
                        font-weight: 600;
                    }
                }
                .itemDescribeRoot {
                    @include boxSize(100%, 100%);
                    @include leftTop(0, 0);
                    opacity: 0;
                    transition: $transitionTime all;
                    position: absolute;
                    background: #347eff;
                    border-radius: 4px;
                    box-shadow: 0 1px 14px 0 rgba(21,23,94,0.1), 2px 2px 10px 0 rgba(22,62,146,0.5);
                    overflow: hidden;
                    .itemDescribeTitle {
                        @include flexCenter();
                        @include boxSize(100%, .22rem);
                        @include fontStyle(#ffffff, .16rem);
                        font-weight: 600;
                        margin-bottom: .16rem;
                        margin-top: .4rem;
                    }
                    .itemDescribeText {
                        @include flexCenter();
                        @include boxSize(100%, auto);
                        @include fontStyle(#ffffff, .14rem);
                        padding: 0 .26rem;
                        white-space: pre-wrap;
                        word-break: break-all;
                        text-align: justify;
                    }
                }
                &:hover{
                    .itemDescribeRoot{
                        opacity: 1;
                    }
                }
            }
        }
    }
}
