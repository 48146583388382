@import "../../assets/css/common";
.newsDetails{
  background: #ffffff !important;
    .box-content {
        //padding: 0 20px;
        .image-wrap{
            display: flex;
            justify-content: center;
            img {
                margin: 10px auto;
                object-fit: cover;
                //width: 100%;
                //width: calc(100% - 80px);
            }
        }
        img {
            margin: 10px auto;
            object-fit: contain;
            width: 100%;
            text-align: center;
            //width: calc(100% - 80px);
        }
    }
    .newsDetailsSubHeader {
        @include boxSize(100%, 4.8rem);
        @include backgroundImageSetting("../../assets/images/news/newsdetails.png");
        margin-top: -.6rem;
        position: relative;
        padding-top: 1.82rem;

        .subHeaderRoot {
            @include boxSize(100%, auto);
            text-align: center;

            .compassProductName {
                @include fontStyle(#ffffff, .48rem);
                font-weight: 600;
                letter-spacing: 1px;
                margin-bottom: 1px;
            }

            .compassProductDescribe {
                @include fontStyle(#ffffff, .24rem);
                letter-spacing: 1px;
                margin-bottom: .5rem;
            }

            button {
                @include fontStyle(#ffffff, .18rem);
                width: 1.34rem;
                margin: 0;
                padding: 0;
            }

            .consultButton {
                border: 1px solid rgba(255,255,255,1);
                border-radius: 2px;
                margin-left: .2rem;
                background:transparent;
                color: rgba(255,255,255,1);
                font-size: 18px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
            }
        }
    }
}

  //.newsDetails .box-content div,
  //.newsDetails p,
  //.newsDetails img {
  //    margin: 10px auto;
  //    object-fit: cover;
  //    //width: 100%;
  //    width: calc(100% - 80px);
  //}

  .newsDetails .box-content {
    margin-top: 30px;
    max-width: 1200px;
    /* text-align: center; */
  }
  .newsDetails .box-content .news-text,
  .newsDetails .box-content .details-bottom {
    text-align: left;
  }
  .newsDetails .box-content .news-title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    line-height: 25px;
  }
  .newsDetails .box-content .img-detail {
    width: 624px;
    height: 465px;
    margin: 10px auto;
    display: block;
  }
  .newsDetails .box-content .ditails-classifi {
    text-align: left;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    line-height: 20px;
  }
  .newsDetails .header-fixed {
    background-color: #000 !important;
    width: 100%;
    position: fixed;
  }