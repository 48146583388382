@import "../../../assets/css/common";
.commonSolutionHeader {
    @include boxSize(100%, 4.8rem);
    @include backgroundImageSetting("../../assets/images/solution/banner.png");
    position: relative;
    padding-top: 1.82rem;
    .subHeaderRoot {
        @include boxSize(100%, auto);
        text-align: center;
        .subHeaderName {
            @include fontStyle(#ffffff, .48rem);
            font-weight: 600;
            letter-spacing: 1px;
            margin-bottom: 1px;
        }
        .subHeaderDescribe {
            @include fontStyle(#ffffff, .24rem);
            letter-spacing: 1px;
            margin-bottom: .5rem;
        }
        button{
            @include fontStyle(#ffffff, .18rem);
            width: 1.34rem;
            margin: 0;
            padding: 0;
        }
    }
}
