@import "../../assets/css/common";

.mobileAndChain {
    margin-top: 0;

    .andChainSubHeader {
        height: 7.7rem;

        .subHeaderRoot {
            @include boxSize(100%, auto);
            text-align: center;

            .compassProductDescribe {
                padding: 0.48rem 0.6rem 0rem 0.6rem;
            }

            button {
                height: .82rem !important;
                width: 2.82rem;
            }

            .consultButton{
                font-size: .3rem;
            }
        }
    }

    .box-content {
        padding-bottom: 0;
        .firstSection {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            margin-top: 1rem;
            margin-bottom: 0.3rem;

            .headerTitle {
                font-size: 0.48rem;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #000000;
                line-height: 1rem;
            }

            .subTitle {
                font-size: 0.28rem;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: #74798c;
                line-height: 0.4rem;
                text-align: center;
                padding: 0 0.4rem;
            }

            .programImg {
                padding: 0.6rem 0;
            }

            .privateContent {
                justify-content: center;
                padding-top: 0.8rem;

                .privateBox {
                    width: calc(100% - 0.8rem);
                    height: 2.46rem;
                    padding-right: 1rem;
                    margin-bottom: 0.6rem;

                    &:nth-child(3) {
                        background: url("../../assets/images/andChain/blue.png");
                        background-size: 100% 100%;
                        color: #ffffff;
                        line-height: 22px;

                        .quare {
                            width: 0.24rem;
                            height: 0.05rem;
                            background: #ffffff;
                        }

                        .detail {
                            color: #ffffff;
                        }

                        .titleName {
                            color: #ffffff;
                        }
                    }

                    &:nth-child(4) {
                        background: url("../../assets/images/andChain/white.png") no-repeat;
                        background-size: 100% 100%;
                        color: #565657;
                        margin-bottom: 0;

                        .quare {
                            width: 0.24rem;
                            height: 0.05rem;
                            background: #347eff;
                        }

                        .titleName {
                            color: #242525;
                        }

                        .detail {
                            font-size: 0.28rem;
                            color: #565657;
                        }
                    }
                }
            }

            .bottomContent {
                background: url('../../assets/images/andChain/zixun.png') 100% 100% no-repeat;
                height: 4.46rem;
                margin-top: 1rem;
                //padding: 0 0.4rem;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                text-align: center;

                .infoContent {
                    width: 100%;
                    padding: 0 0.4rem;

                    .topBigTitle {
                        font-size: 0.36rem;
                        text-align: center;
                    }

                    .bottomSubTitle {
                        font-size: 0.28rem;
                        text-align: center;
                        padding-top: 0.3rem;
                        display: block;
                    }
                }

                .zixun {
                    text-align: center;
                    position: relative;
                    right: 0;
                    margin-top: 0.4rem;
                }
            }
        }

        .secSection {
            background-color: rgba(248, 249, 252, 1);
            padding-top: 0.6rem;
            margin-top: 1.4rem;

            .content {
                position: relative;
                height: auto;
                width: 100%;
                padding-top: 40px;
                display: flex;


                .leftContent {
                    z-index: 2;
                    position: relative;
                    right: 0;

                    .box {
                        height: auto;
                        width: 95%;
                        background: #ffffff;
                        border-radius: 2px;
                        display: flex;
                        align-items: flex-start;
                        padding: 0.4rem;
                        margin: 0 auto 0.48rem auto;
                        flex-direction: column;
                        justify-content: center;

                        .point {
                            width: 0.10rem;
                            height: 0.1rem;
                            background: #1890ff;
                            border-radius: 10px;
                            line-height: 0.20rem;
                            margin: 0 auto;
                        }

                        .leftName {
                            font-size: 0.36rem;
                            font-family: PingFangSC, PingFangSC-Semibold;
                            font-weight: 600;
                            text-align: left;
                            color: rgba(0, 0, 0, 0.85);
                        }

                        .rightTitle {
                            font-size: 0.28rem;
                            font-family: PingFangSC, PingFangSC-Regular;
                            font-weight: 400;
                            color: rgba(0, 0, 0, 0.85);
                            line-height: 0.40rem;
                            width: 100%;
                            padding: 0.2rem 0 0 0.6rem;
                        }
                    }
                }

                .rightImg {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 5rem;
                }
            }
        }

        .thirdSection{
            margin-top: 0.6rem;
        }
        .forthSection{
            margin-bottom: 0;
        }
    }
}