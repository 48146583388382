@import "../../assets/css/common";

.news {
  background: #ffffff !important;
  .commonHeader {
    background-color: rgba(2, 22, 55, 0.1);
  }
  .newsSubHeader {
    @include boxSize(100%, 4.8rem);
    @include backgroundImageSetting("../../assets/images/news/headerBg.png");
    margin-top: -0.6rem;
    position: relative;
    padding-top: 1.82rem;

    .subHeaderRoot {
      @include boxSize(100%, auto);
      text-align: center;

      .compassProductName {
        @include fontStyle(#ffffff, 0.48rem);
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 1px;
      }

      .compassProductDescribe {
        @include fontStyle(#ffffff, 0.24rem);
        letter-spacing: 1px;
        margin-bottom: 0.5rem;
      }

      button {
        @include fontStyle(#ffffff, 0.18rem);
        width: 1.34rem;
        margin: 0;
        padding: 0;
      }

      .consultButton {
        border: 1px solid rgba(255, 255, 255, 1);
        border-radius: 2px;
        margin-left: 0.2rem;
        background: transparent;
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
      }
    }
  }
  .news-tabMenu {
    .ant-menu-item {
      color: black !important;
    }
  }
  .ant-tabs-nav {
    .ant-tabs-tab {
      font-family: PingFangSC, PingFangSC-Regular;
      color: #000000;
      line-height: 40px;
      letter-spacing: 0px;
      width: 120px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
    .ant-tabs-ink-bar {
      display: none;
      width: 120px !important;
      height: 4px;
      background: #337dff;
    }
  }
  .active {
    width: 120px;
    padding-bottom: 8px;
    border-bottom: 2px solid #337dff;
    color: #337dff;
  }
  .header-fixed {
    background-color: #000 !important;
    width: 100%;
    position: fixed;
  }
  .box-content {
    max-width: 1160px;
    margin: 0 auto 80px auto;
    text-align: center;
    padding: 0px 0.2rem;
    .news-list {
      display: flex;
      margin: 40px 0 60px 0;
      .imgBox {
        img {
          width: 260px;
          height: 140px;
        }
      }
      .news-list-item2 {
        margin: 0 61px 0 53px;
        position: relative;
        h2 {
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 1);
          line-height: 48px;
        }
        p {
          font-size: 19px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(153, 153, 153, 1);
          line-height: 21px;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 120px;
          width: 1px;
          height: 140px;
          background: #ebedf1;
        }
      }
      .news-list-item3 {
        text-align: left;
        h3 {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 1);
          line-height: 25px;
        }
        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #74798c;
          line-height: 21px;
        }
        div {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(119, 119, 119, 1);
          line-height: 21px;
        }
      }
    }

    .news-tab {
      width: 500px;
      margin: 0 auto;
      list-style: none;
      cursor: pointer;
      display: flex;
      justify-content: space-around;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
      line-height: 20px;
    }
    .ant-pagination-item-active {
      a {
        color: #ffffff;
        background: #337dff;
      }
    }
  }
}
