.rc-footer {
    min-width: 1200px;
    position: relative;
    clear: both;
    color: rgba(255, 255, 255, 0.4);
    font-size: 14px;
    line-height: 1.5;
    //background-color: #000;
    background: url("../../assets/images/footer/bg.png") no-repeat;
    background-size: cover;
    .rc-footer-container {
      //max-width: unset;
      //padding: 60px;
      //margin: 0 auto;
      padding-top: 60px;
    }
    .rc-footer-columns {
      justify-content: space-between;
      display: flex;
      margin: 0 auto;
      width: 100%;
      max-width: 11.6rem;
    }
    .rc-footer-columns .footer-logo{
      width: 140px;
      height: 37px;
    }
    .rc-footer-column .title{
      font-size:16px;
  
      font-weight:600;
      color:rgba(255,255,255,1);
      line-height:48px;
    }
    .rc-footer-column .footer-item-title{
      font-size:16px;
  
      font-weight:600;
      color:rgba(255,255,255,1);
      line-height:20px;
    }
    .rc-footer-column .footer-item-boder{
      border: 2px solid rgba(51,125,255,1);
      width: 18px;
      margin-top: 14px;
    }
    .rc-footer-column .footer-box{
      margin-top: 10px;
    }
    .rc-footer-item{
      font-size:14px;
      font-weight:400;
      color:rgba(255,255,255,1);
      line-height:48px;
    }
    .rc-footer-item .rc-footer-item-link{
      color: #fff;
    }
    .rc-footer-item img{
      margin-top: 18px;
    }
    .rc-footer-bottom-container {
      max-width: calc(100% - 120px);
      width: 100%;
      text-align: center;
      margin: 0 auto;
      padding: 16px 0 10px 0;
      font-size:14px;
      font-weight:400;
      color:rgba(255,255,255,1);
      line-height:20px;
    }
  
}
  