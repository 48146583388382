@import "../../assets/css/common";

.mobileHighBoxCom {
    display: flex;
    justify-content: space-around;
    padding: 0.6rem 0;
    width: 100%;
    max-width: 1160px;

    .scenariosBox {
        width: 5.4rem;
        height: 7.6rem;
        position: relative;

        &:hover {
            background: #FFFFFF;

            .bgImg,
            .topImg {
                display: block;
            }

            .highBoxComItemName {
                .titleName {
                    background: #347eff;
                    color: #ffffff;
                }

                .blueLeft {
                    background: url("../../assets/images/andChain/blueLeft.png") 100% 100%;
                }

                .blueRight {
                    background: url("../../assets/images/andChain/blueLeft.png") 100% 100%;
                }
            }

            .mask {
                display: block;
            }
        }

        .topImg {
            position: absolute;
            width: 2.4rem;
            height: 2.4rem;
            left: 50%;
            transform: translate(-50%, 0%);
            top: 0.2rem;
        }

        .highBoxComItemName {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 44px;
            left: 50%;
            transform: translate(-50%, 0%);

            .titleName {
                .titleName {
                    width: 1.2rem;
                    height: 0.52rem;
                    background: #347eff;
                    line-height: 0.52rem;
                    font-size: 0.32rem;
                    font-weight: 600;
                }
            }

            //.blueLeft {
            //    width: 0.4rem;
            //    height: 0.44rem;
            //}
            //
            //.blueRight {
            //    width: 0.4rem;
            //    height: 0.44rem;
            //}
        }

        .mask {
            position: absolute;
            top: 2.5rem;
            padding: 0px 0.7rem;
            display: block;
            font-size: 0.28rem;
            color: rgba(0, 0, 0, 0.85);
            line-height: 0.4rem;
        }
    }

    .slider-decorator-0 {
        bottom: -.7rem !important;
    }

    .am-carousel-wrap-dot > span {
        display: block;
        width: .6rem;
        height: .08rem;
        margin: 0 .1rem;
        border-radius: 0;
        background: rgba(24, 144, 255, .2);
    }

    .am-carousel-wrap-dot-active > span {
        background-color: #1890ff;
    }
}