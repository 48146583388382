@import "../../assets/css/common";
.mobileHome {
    .homeSubHeader {
        height: 8.2rem;
        .homeCarouselRoot {
            .carouselItem{
                height: 8.2rem;
                .carouselItemContent {
                    max-width: 100%;
                    padding-top: 1rem;
                    text-align: center;
                    div:nth-child(1){
                        @include flexCenter();
                        height: 1.34rem;
                        margin-bottom: .48rem;
                    }
                    div:nth-child(2), div:nth-child(3){
                        @include flexCenter();
                        font-size: .32rem;
                        margin-bottom: .16rem;
                    }
                    div:nth-child(3){
                        margin-bottom: .6rem;
                    }
                    .ant-btn{
                        width: 2.82rem;
                        height: .82rem !important;
                        font-size: .32rem;
                    }
                }
            }
            .left, .right{
                display: none;
            }
            .slick-dots {
                li {
                    @include boxSize(.6rem, .08rem);
                    margin-right: .2rem;
                    &:last-child{
                        margin-right: 0;
                    }
                }
                li button{
                    @include boxSize(.6rem, .08rem);
                }
            }
        }

    }
    .homeDivRoot{
        padding: .8rem 0 1.2rem;
        .homeDiv {
            max-width: 100%;
            .homeDivTitle {
                height: .67rem;
                font-size: .48rem;
            }
            .latestNewsRoot {
                .latestNewsItem {
                    width: 100%;
                    background-color: #ffffff;
                    .latestNewsItemHeader1, .latestNewsItemHeader2, .latestNewsItemHeader3 {
                        height: 2.35rem;
                        padding-top: .41rem;
                        padding-left: .4rem;
                        .latestNewsItemHeaderName{
                            font-size: .48rem;
                            height: .67rem;
                            justify-content: flex-start;
                            margin-bottom: .28rem;
                        }
                        .latestNewsItemHeaderButton {
                            @include boxSize(1.62rem, .64rem);
                            margin: 0;
                            font-size: .28rem;
                            border-radius: .4rem;
                        }
                    }
                    .newsCardRoot {
                        padding: 0;
                        .newsCard {
                            padding-top: .3rem;
                            padding-bottom: .3rem;
                            .newsCardTitle {
                                min-height: 1rem;
                                font-size: .32rem;
                                margin-bottom: .16rem;
                            }
                            .newsCardTime {
                                font-size: .28rem;
                            }
                        }
                    }
                }
            }
            .homeNewsButton {
                @include boxSize(3.42rem, .82rem);
                font-size: .3rem;
                border-radius: .4rem;
            }
            .coreCompetenceRoot{
                flex-direction: column;
                .coreCompetence{
                    width: 100%;
                    padding: 0 .4rem;
                    margin-top: 1rem;
                    .coreCompetenceImgRoot {
                        @include boxSize(4.6rem, 3.8rem);
                        margin: 0 auto .2rem;
                        padding: 0;
                        img{
                            @include boxSize(4rem, 4rem);
                            object-fit: contain;
                        }
                    }
                    .coreCompetenceBottom {
                        padding: .6rem 1rem;
                        .coreCompetenceName {
                            font-size: .36rem;
                            height: .52rem;
                            margin-bottom: .4rem;
                        }
                        .coreCompetenceText {
                            font-size: .28rem;
                            letter-spacing: 0.5px;
                        }
                    }
                }
                .coreCompetence:first-child{
                    margin-top: 0;
                }
            }
            .mobileHomeProductRoot {
                @include boxSize(100%, auto);
                padding: 0 .4rem;
                .mobileHomeProduct {
                    @include boxSize(100%, auto);
                    margin-bottom: .3rem;
                    .mobileProductItem {
                        @include boxSize(100%, 1.1rem);
                        @include flexAlignCenter();
                        @include fontStyle(rgba(0,0,0,0.85), .36rem);
                        justify-content: space-between;
                        border-radius: .08rem;
                        background-color: #f8f9fc;
                        font-weight: 600;
                        position: relative;
                        padding-left: 1.2rem;
                        padding-right: .3rem;
                        cursor: pointer;
                        span{
                            position: relative;
                            z-index: 3;
                        }
                        .anticon-plus{
                            color: #347eff;
                        }
                        .anticon-plus, .anticon-minus {
                            font-size: .4rem;
                        }
                        &:before{
                            content: '';
                            z-index: 3;
                            position: absolute;
                            @include leftTop(.4rem, 50%);
                            @include boxSize(.16rem, .16rem);
                            transform: translateY(-50%);
                            border-radius: 50%;
                            background-color: #347eff;
                        }
                        .mobileProductItemActiveBg1 {
                            @include boxSize(0, 140%);
                            @include leftTop(0, -20%);
                            z-index: 2;
                            position: absolute;
                            background-color: #236ae5;
                            border-top-right-radius: 3rem;
                            border-bottom-right-radius: 3rem;
                        }
                        .mobileProductItemActiveBg2{
                            @include boxSize(0, 140%);
                            @include leftTop(0, -20%);
                            z-index: 1;
                            position: absolute;
                            background-color: #2d75f5;
                            border-top-right-radius: 3rem;
                            border-bottom-right-radius: 3rem;
                        }
                    }
                    .mobileProductItemActive {
                        overflow: hidden;
                        background-color: #347eff;
                        color: #ffffff;
                        border-radius: .08rem;
                        box-shadow: 0 0 .2rem 0 rgba(14,19,76,0.2);
                        &:before{
                            content: '';
                            background-color: #ffffff;
                        }
                        .mobileProductItemActiveBg1 {
                            width: 80%;
                        }
                        .mobileProductItemActiveBg2{
                            width: 90%;
                        }
                    }
                    .mobileHomeProductItemBottom {
                        @include boxSize(100%, auto);
                        overflow: hidden;
                        background-color: #f8f9fc;
                        border-radius: 4px;
                        padding: .6rem .4rem;
                        position: relative;
                        .mobileProductName {
                            @include boxSize(100%, .48rem);
                            @include flexAlignCenter();
                            @include fontStyle(#1f4d87, .36rem);
                            font-weight: 600;
                            margin-bottom: .24rem;
                        }
                        .mobileProductDescribe, .mobileProductCase {
                            @include fontStyle(rgba(0,0,0,0.85), .24rem);
                            margin-bottom: .24rem;
                        }
                        .mobileProductCase{
                            margin-bottom: .7rem;
                        }
                        .mobileProductButton {
                            @include boxSize(1.62rem, .64rem);
                            @include flexCenter();
                            @include fontStyle(#ffffff, .28rem);
                            position: relative;
                            z-index: 2;
                            background-color: #347eff;
                            cursor: pointer;
                            border-radius: .4rem;
                        }
                        .mobileProductIcon {
                            position: absolute;
                            @include rightBottom(.4rem, .88rem);
                            width: calc(100% - .8rem);
                        }
                    }
                }
            }
            .partnersRoot {
                padding: 0 .4rem;
                img {
                    @include boxSize(3.2rem, 1.4rem);
                    object-fit: cover;
                    margin-bottom: .3rem;
                    box-shadow: 0 0 .1rem 0 rgba(3,6,23,0.1);
                }
            }
        }
    }
    .homeBottomRoot {
        height: auto;
        padding: .6rem .4rem;
        .homeBottom {
            justify-content: center;
            flex-direction: column;
            max-width: 100%;
            padding: 0;
            .homeBottomLeft {
                height: auto;
                align-items: center;
                span:nth-child(1){
                    text-align: center;
                    font-size: .36rem;
                    margin-bottom: .3rem;
                }
                span:nth-child(2){
                    font-size: .28rem;
                    margin-bottom: .4rem;
                }
            }
            .ant-btn{
                flex: none;
                width: 2.82rem;
                height: .82rem !important;
                font-size: .3rem;
                border-radius: .04rem;
                padding: 0;
            }
        }
    }
}
