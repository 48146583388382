@import "../../assets/css/common";

.anstar {
    @include boxSize(100%, auto);
    margin-top: -.6rem;
    overflow: hidden;
    position: relative;
    background-color: #FFFFFF !important;

    .anstarSubHeader {
        @include boxSize(100%, 4.8rem);
        @include backgroundImageSetting("../../assets/images/anstar/banner.png");
        position: relative;
        padding-top: 1.82rem;

        .subHeaderRoot {
            @include boxSize(100%, auto);
            text-align: center;

            .compassProductName {
                @include fontStyle(#ffffff, .48rem);
                font-weight: 600;
                margin-bottom: 1px;
            }

            .compassProductDescribe {
                @include fontStyle(#ffffff, .24rem);
                letter-spacing: 1px;
                margin-bottom: .5rem;
            }

            button {
                @include fontStyle(#ffffff, .18rem);
                width: 1.34rem;
                margin: 0;
                padding: 0;
            }

            .consultButton {
                border: 1px solid rgba(255, 255, 255, 1);
                border-radius: 2px;
                //margin-left: .2rem;
                background: transparent;
                color: rgba(255, 255, 255, 1);
                //font-size: 0.36rem;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
            }
        }
    }

    .anstarContent {
        .firstSection {
            display: flex;
            //justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            padding-top: 60px;

            .headerTitle {
                font-size: 34px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #000000;
                line-height: 48px;
            }

            .subTitle {
                font-size: 16px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                text-align: center;
                max-width: 1160px;
                color: #74798c;
                line-height: 22px;
                padding-top: 16px;
            }

            .imgModel {
                padding-top: 60px;
                width: 1160px;
                object-fit: contain;
            }

            .borderContent {
                width: 100%;
                max-width: 1160px;
                height: 84px;
                border: 5px dashed #a5d2ff;
                border-radius: 53px;
                margin: 60px 0px 80px 0px;

                .safetyBox {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    align-items: center;
                    padding: 0px 80px;

                    .safetyButton {
                        width: 250px;
                        height: 44px;
                        background: #F2F6FB;
                        border-radius: 22px;
                        color: #347EFF;
                        text-align: center;
                        line-height: 44px;
                        font-size: 18px;
                        font-family: PingFangSC, PingFangSC-Semibold;
                        font-weight: 600;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        //padding: 0 0.2rem;
                        //line-height: 0.32rem;

                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3) {
                            margin-top: -26px;
                        }

                        &:nth-child(4),
                        &:nth-child(5) {
                            margin-top: 36px;
                        }
                    }

                    .chooseBtn {
                        background: #347eff;
                        color: #ffffff;
                    }
                }
            }
        }

        .backgroundColor {
            background: #f8f9fc;
            padding-top: 60px;
        }

        .lastHeight{
            height: 750px;
        }
    }

    .bottomAnstarSafety{
        margin-top: 140px;
    }
}