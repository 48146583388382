$maxWidth: 11.6rem;
$transitionTime: .3s;

@mixin backgroundImageSetting ($url) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

@mixin boxSize ($width, $height) {
  width: $width;
  height: $height;
}

@mixin boxSizeAndLine ($width, $height, $lineHeight) {
  width: $width;
  height: $height;
  line-height: if($lineHeight, $lineHeight, $height);
}

@mixin fontStyle ($color, $fontSize) {
  color: $color;
  font-size: $fontSize;
}

@mixin fontAllStyle ($color, $fontSize, $fontFamily, $fontWeight) {
  color: $color;
  font-size: $fontSize;
  font-family: $fontFamily;
  font-weight: if($fontWeight, $fontWeight, 400)
}

@mixin leftTop ($left, $top) {
  left: $left;
  top: $top;
}

@mixin leftBottom ($left, $bottom) {
  left: $left;
  bottom: $bottom;
}

@mixin rightTop ($right, $top) {
  right: $right;
  top: $top;
}

@mixin rightBottom ($right, $bottom) {
  right: $right;
  bottom: $bottom;
}

@mixin textEllipsis($width) {
  width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

@mixin hideText() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin fl() {
  display: inline-block;
  float: left;
}

@mixin boxCenter($width) {
  width: $width;
  margin: 0 auto;
}

@mixin height($height, $lineHeight) {
  height: $height;
  line-height: if($lineHeight, $lineHeight, $height);
}

@mixin heightAndMinHeight($height, $minHeight) {
  height: $height;
  min-height: $minHeight;
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flexAlignCenter {
  display: flex;
  align-items: center;
}

@mixin leftIcon($bgColor, $width, $height, $mgr) {
  &:before {
    content: '';
    display: inline-block;
    width: $width;
    height: $height;
    background: $bgColor;
    margin-right: $mgr;
    transform: translateY(13%);
  }
}
