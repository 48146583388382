@import "../../assets/css/common";
.commonProgramAns{
    @include boxSize(100%, auto);
    position: relative;
    .programAnsHeader {
        @include boxSize(100%, 3rem);
        @include backgroundImageSetting("../../assets/images/programAns/programAnsBg.png");
        margin-top: -.6rem;
        position: relative;
        padding-top: 1rem;
        .subHeaderRoot {
            @include boxSize(100%, auto);
            text-align: center;
            .programAnsName {
                @include fontStyle(#ffffff, .32rem);
                font-weight: 600;
                letter-spacing: 1px;
                margin-bottom: 1px;
            }
            .programAnsDescribe {
                @include fontStyle(#ffffff, .18rem);
                letter-spacing: 1px;
                margin-bottom: .5rem;
            }
        }
    }
    .programAnsForm{
        @include boxSize(6rem, auto);
        background: #ffffff;
        border-radius: 2px;
        box-shadow: 0 6px 16px 0 rgba(0,0,0,0.08), 0 9px 4px 0 rgba(0,0,0,0.05), 0 12px 4px rgba(0,0,0,0.03);
        z-index: 2;
        height: auto;
        position: relative;
        padding: 40px;
        margin: -80px auto 150px;
        padding: 40px;
        .ant-form{
            .ant-row{
                margin: 15px 0 0 !important;
                .ant-form-item-label{
                    padding: 0 0 5px;
                }
                .ant-form-item-required{
                    opacity: 0.85;
                    font-size: 14px;
                    text-align: left;
                    color: #000000;
                }
                .btn{
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
    .affirmModal{
        top: 30%;
        height: auto;
        .affirmContent{
            text-align: center;
            padding: .3rem 0 .1rem 0;
            .img{
                width: .72rem;
            }
            .succeed{
                font-size: .24rem;
                font-weight: 500;
                color: rgba(0,0,0,0.85);
                margin-top: .2rem;
            }
            .hint{
                font-size: .14rem;
                color: rgba(0,0,0,0.45);
                margin-top: .1rem;
            }
            .consultButton{
                width: 1.34rem;
                border: 1px solid #347eff;
                border-radius: 2px;
                font-size: .18rem;
                color: #2e7aff;
                letter-spacing: .6px;
                margin-top: .3rem;
            }
        }
    }
}
