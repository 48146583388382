@import "../../assets/css/common";
.mobileHeader {
    @include boxSize(100%, 1.2rem);
    @include flexAlignCenter();
    position: relative;
    z-index: 16;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 0 .4rem;
    .mobileHeaderLogo {
        height: .57rem;
    }
    .mobileMenuIcon {
        @include boxSize(.4rem, .4rem);
    }
    .ant-dropdown {
        top: 1.2rem;
    }
    .mobileHeaderMenuRoot {
        @include boxSize(100vw, calc(100vh - 1.2rem));
        @include leftTop(0, 1.2rem);
        z-index: 10;
        position: fixed;
        .mobileHeaderMenu {
            @include boxSize(100%, auto);
            background-color: #f8f9fc;
            position: relative;
            z-index: 10;
            padding: .4rem .4rem 1rem;
            .mobileHeaderSubMenuRoot {
                @include boxSize(100%, auto);
                position: relative;
                .mobileHeaderSubMenuTitle {
                    @include boxSize(100%, .93rem);
                    @include fontStyle(rgba(0,0,0,0.85), .32rem);
                    @include flexAlignCenter();
                    font-weight: 600;
                    justify-content: space-between;
                    border-bottom: 1px solid rgba(0,0,0,0.17);
                    .headerMenuName {
                        @include fontStyle(rgba(0,0,0,0.85), .32rem);
                    }
                }
                .mobileHeaderSubMenu {
                    @include boxSize(100%, 0);
                    overflow: hidden;
                    padding: 0;
                    .mobileHeaderSubMenuItem {
                        @include fontStyle(#74798c, .28rem);
                        margin-bottom: .3rem;
                    }
                    .menuSubTitle {
                        color: rgba(0,0,0,0.85);
                        font-weight: 600;
                    }
                }
                .mobileHeaderSubMenuActive {
                    height: auto;
                    padding: .4rem 0 .1rem;
                    border-bottom: 1px solid rgba(255,255,255,.2);
                }
            }
        }
        .mobileMenuMask {
            @include boxSize(100%, 100%);
            @include leftTop(0, 0);
            position: absolute;
            background-color: #000000;
            opacity: 0.4;
            z-index: 2;
        }
    }
}
