@import "../../assets/css/common";

.mobileAndLogistics{
    margin-top: 0;
    .andLogisticsSubHeader {
       height: 7.72rem;
        .subHeaderRoot {
            display: flex;
            flex-direction: column;
            align-items: center;

            .andLogisticsProductName {
                font-size: 0.48rem;
            }
            .andLogisticsDescribe {
                font-size: .32rem;
            }
            button{
                font-size: .3rem;
                height: .82rem !important;
                width: 2.82rem;
            }
            .consultButton {
                margin-left: 0;
                margin-top: .3rem;
            }
        }
    }
    .box-content {
        margin-bottom: 1rem;
        .firstSection {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            //width: calc(100% - 0.8rem);
            //margin: 0.6rem auto;
            width: 100%;
            padding: 0 0.4rem;

            .headerTitle {
                font-size: 0.48rem;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #000000;
                line-height: 0.6rem;
                text-align: center;
            }

            .subTitle {
                font-size: 0.28rem;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: #74798c;
                line-height: 0.4rem;
                text-align: center;
                padding: 0.2rem 0rem;
                text-align: justify;
            }
    
            .programImg {
                padding: 0.6rem 0;
                width: 6.7rem;
                height: auto;
                object-fit: contain;
            }
    
            .boxContent {
                display: flex;
                // width: 70%;
                // justify-content: space-between;
                justify-content: center;
                padding-top: 0.60rem;
    
                .box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 0.50rem;
    
                    .topImg {
                        width: 180px;
                        height: 180px;
                    }
    
                    .botContent {
                        width: 240px;
                        height: 245px;
                        background: #f8f9fc;
                        border-radius: 2px;
                        padding: 30px;
                        margin-top: 25px;
    
                        .titleName {
                            font-size: 18px;
                            font-family: PingFangSC, PingFangSC-Semibold;
                            font-weight: 600;
                            text-align: center;
                            color: #1f4d87;
                            line-height: 14px;
                            border-bottom: 5px solid rgba(52, 126, 255, 0.2);
                            width: 100px;
                            margin: 0 auto;
                        }
    
                        .titleContent {
                            padding-top: 15px;
                            font-size: 14px;
                            font-family: PingFangSC, PingFangSC-Regular;
                            font-weight: 400;
                            text-align: justify;
                            color: rgba(0, 0, 0, 0.85);
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    
        .secSection {
            background-color: rgba(248, 249, 252, 1);
            margin-top: 1.2rem;
            padding: 0.6rem 0 0 0;

            .content {
                position: relative;
                height: auto;
                width: 100%;
                padding-top: 0.8rem;
                display: flex;


                .leftContent {
                    z-index: 2;
                    position: relative;
                    right: 0;
                    width: 100%;

                    .box {
                        height: auto;
                        width: 95%;
                        background: #ffffff;
                        border-radius: 2px;
                        display: flex;
                        align-items: flex-start;
                        padding: 0.4rem;
                        margin: 0 auto 0.48rem auto;
                        flex-direction: column;
                        justify-content: center;

                        .point {
                            width: 0.10rem;
                            height: 0.1rem;
                            background: #1890ff;
                            border-radius: 10px;
                            line-height: 0.20rem;
                            margin: 0 auto;
                        }

                        .leftName {
                            font-size: 0.36rem;
                            font-family: PingFangSC, PingFangSC-Semibold;
                            font-weight: 600;
                            text-align: left;
                            color: rgba(0, 0, 0, 0.85);
                        }

                        .rightTitle {
                            font-size: 0.28rem;
                            font-family: PingFangSC, PingFangSC-Regular;
                            font-weight: 400;
                            color: rgba(0, 0, 0, 0.85);
                            line-height: 0.40rem;
                            width: 100%;
                            padding: 0.2rem 0 0 0.6rem;
                        }
                    }
                }
                .rightProgramImg {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 5rem;
                }
            }
        }
    }
}
