@import "../../assets/css/common";

.mobileCommonBottomBox {
    background: url("../../assets/images/andChain/zixun.png") no-repeat;
    width: 100%;
    height: 13rem;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    background-size: cover;
    position: relative;
    align-items: center;
    padding: 1rem 0.4rem;
    justify-content: center;

    .commonBannarContent {
        width: 100%;

        .topBigTitle {
            font-size: 0.48rem;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            color: #ffffff;
            line-height: 0.67rem;
            letter-spacing: 0px;
            text-align: center;
            //margin-top: 1rem;
        }

        .imagesContent {
            margin-top: 0.6rem;
            position: relative;
            align-items: center;

            .ant-carousel {
                .slick-dots-bottom {
                    bottom: -0.55rem;
                }
            }

            .slick-dots {
                li {
                    @include boxSize(0.16rem, 0.16rem);
                    margin-right: 0.4rem;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                button{
                    height: 0.16rem;
                    width: 0.16rem;
                    border-radius: 0.16rem;
                }
            }

            .carouselContent {
                display: flex;
                justify-content: center;
                flex-direction: column;
                height: auto;
                align-items: center;

                .imageStyle {
                    height: 1.75rem;
                    width: 4rem;
                    margin-right: 0;
                    margin-bottom: 0.4rem;
                }
            }

            .circleOutlined {
                position: absolute;
                top: 50%;
                opacity: 1;
                font-size: 0.53rem;
                cursor: pointer;
            }

            .left {
                left: 0.5rem;
            }

            .right {
                right: 0.5rem;
            }

            // .bottomPoint {
            //     position: absolute;
            //     bottom: -0.4rem;
            //     display: flex;
            //     justify-content: center;
            //     width: 100%;
            //     color: #ffffff;
            // }
        }

        .bottomContentInfo {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-top: 0.6rem;

            .infoContent {
                margin-top: 0.1rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                .bottomContentTitle {
                    font-size: 0.36rem;
                    text-align: center;
                    line-height: 0.5rem;
                    //width: 90%;
                }

                .bottomContentSubTitle {
                    font-size: 0.28rem;
                    text-align: center;
                    line-height: 0.4rem;
                    padding-top: 0.3rem;
                    display: block;
                }
            }

            .zixun {
                width: 2.82rem;
                height: 0.82rem;
                text-align: center;
                border-radius: 0.04rem;
                line-height: 0.82rem;
                font-size: 0.3rem;
                margin-top: 0.4rem;
            }
        }
    }
}