@import "../../assets/css/common";
.mobileNews {
    .box-content {
        max-width: 100%;
        padding: 0 0.4rem;
        margin-top: 0;
        .mobile-news-list {
            margin-bottom: 40px;
            position: relative;
            .title {
                font-size: 18px;
                font-weight: 600;
                color: #000000;
                line-height: 25px;
                text-align: left;
                a {
                    color: #000000;
                }
            }
            .content {
                display: flex;
                margin: 10px 0 15px 0;
                justify-content: space-between;
                span {
                    text-align: left;
                    font-size: 15px;
                    color: #74798c;
                    line-height: 21px;
                    //padding-right: 0.4rem;
                    width: calc(100% - 2.4rem - 0.4rem);
                }
                img {
                    width: 2.4rem;
                    height: 63px;
                    border: 1px solid #f0f2f5;
                    border-radius: 2px;
                }
            }
            .date {
                text-align: left;
                font-size: 14px;
                color: #adb7c3;
                line-height: 20px;
                span:first-child {
                    margin-right: 8px;
                }
            }
            &::after {
                position: absolute;
                content: '';
                width: 95%;
                height: 1px;
                background: #ebedf1;
                left: 2%;
                bottom: -20px;
            }
        }
    }
}