.valueContent {
    display: flex;
    // width: 60%;
    justify-content: space-between;
    padding-top: 60px;
    width: 100%;
    max-width: 1160px;

    .valueBox {
        width: 320px;
        // height: 459px;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

        .valueImg {
            width: 320px;
            height: 200px;
            border-radius: 4px 4px 0px 0px;
            object-fit: cover;
        }

        .introduceTitle {
            padding: 20px 40px;

            .subName {
                font-size: 18px;
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                text-align: left;
                color: rgba(0, 0, 0, 0.85);
                line-height: 22px;
                text-align: center;
            }

            .line {
                width: 25px;
                height: 3px;
                background: #74bcff;
                text-align: center;
                // padding: 10px 0px;
                margin: 10px auto 16px auto;
            }

            .bottomTitle {
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: rgba(0,0,0,0.65);
                line-height: 22px;
                text-align: justify;
            }
        }
    }
}