@import "../../assets/css/common";

.commonBottomContent {
    background: url("../../assets/images/andChain/zixun.png") no-repeat;
    width: 100%;
    // max-width: 1160px;
    max-height: 565px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    background-size: cover;
    //margin-top: 120px;
    position: relative;
    align-items: center;
    padding: 60px 0;

    .commonBannarContent {
        width: 100%;
        max-width: 1160px;
        // height: 190px;

        .topBigTitle {
            font-size: 34px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            color: #ffffff;
            line-height: 48px;
            letter-spacing: 0px;
            text-align: center;
        }

        .imagesContent {
            margin-top: 60px;
            height: 190px;
            width: 100%;
            position: relative;

            .ant-carousel {
                .slick-dots-bottom {
                    bottom: -50px;
                }
            }

            .slick-dots {
                li {
                    @include boxSize(8px, 8px);
                    margin-right: 20px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
                button{
                    height: 8px;
                    width: 8px;
                    border-radius: 8px;
                }
            }

            .carouselContent {
                display: flex;
                justify-content: center;

                .imageStyle {
                    margin-right: 40px;
                    width: 360px;
                    height: 140px;
                    object-fit: contain;

                    &:nth-child(3n) {
                        margin-right: 0px;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }

            .circleOutlined {
                position: absolute;
                top: 60px;
                opacity: 0.5;
                font-size: 24px;
                cursor: pointer;
            }

            .left {
                left: -44px;
            }

            .right {
                right: -44px;
            }

            .bottomPoint {
                position: absolute;
                bottom: -40px;
                display: flex;
                justify-content: center;
                width: 100%;
                color: #ffffff;
            }
        }


        .bottomContentInfo {
            display: flex;
            justify-content: space-between;
            margin-top: 50px;

            .infoContent {
                margin-top: 30px;

                .bottomContentTitle {
                    font-size: 24px;
                    font-family: PingFangSC, PingFangSC-Semibold;
                    font-weight: 600;
                    text-align: left;
                    line-height: 33px;
                }

                .bottomContentSubTitle {
                    font-size: 16px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    text-align: left;
                    line-height: 22px;
                    padding-top: 15px;
                    display: flex;
                    justify-content: space-between;
                }
            }

            .zixun {
                text-align: center;
                line-height: .41rem;
                margin-top: 60px;
                cursor: pointer;
                width: 1.34rem;
                height: 0.41rem;
                color: #ffffff;
                font-size: 0.18rem;
                border: 1px solid #FFFFFF;
                border-radius: 2px;
                background-color: transparent;
            }
        }
    }
}