@import "../../assets/css/common";

// 左侧回到顶部
.scroll-top {
    z-index: 100;
    width: 180px;
    position: fixed;
    left: 30px;
    top: 230px;
    transition: all 0.3s;
    scroll-behavior: smooth;

    .scroll-top-box {
        box-shadow: 0px 2px 20px 0px rgba(19, 99, 207, 0.3);
        border-radius: 30px;
        padding: 25px 15px;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        > img {
            margin-bottom: 8px;
            width: 100px;
            height: 100px;
        }
        h6 {
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 600;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            line-height: 20px;
            margin-bottom: 16px;
            span {
                color: #0f60ce;
            }
        }
        .scroll-code {
            // border: 16px #E6F0FD solid;
            border-radius: 6px;
            margin-bottom: 16px;
            img {
                width: 116px;
                width: 116px;
            }
        }
        h5 {
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: center;
            line-height: 20px;
            color: #0f60ce;
            margin-bottom: 16px;
            font-weight: 600;
            .clickBtn {
                width: 84px;
                height: 24px;
                font-size: 14px;
                font-weight: 600;
                text-align: center;
                color: #ffffff;
                line-height: 24px;
                cursor: pointer;
                background-color: #0f60ce;
                border-radius: 6px;
                margin: 0 auto;
                margin-top: 8px;
            }
        }
        .scroll-phone-info,
        .scroll-phone {
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            line-height: 20px;
            margin-bottom: 0px;
        }
        .scroll-phone {
            font-size: 18px;
            color: #0f60ce;
            font-weight: 600;
            // margin-bottom: 8px;
            line-height: 25px;
        }
    }
    .scroll-icon {
        cursor: pointer;
        width: 44px;
        height: 44px;
        margin: 0 auto;
        line-height: 44px;
        font-size: 16px;
        color: #4d8bdf;
        background-color: #fff;
        border-radius: 50%;
        text-align: center;
        margin-top: 17px;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
    }
}
