@import "../../assets/css/common";
.mobileJoinus {
  .box-content {
    .joinus-intr {
      padding: 0 20px;
      div {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #000000;
        line-height: 34px;
        margin-bottom: 20px;
      }
      p {
        font-size: 14px;
        text-align: justify;
        color: rgba(0, 0, 0, 0.85);
        line-height: 20px;
      }
    }
    .joinus-rec {
      height: auto !important;
      margin-top: 70px;
      //min-height: 730px;
      padding: 0 0.4rem 1.4rem 0.4rem;
      .jobInfoTitle {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #000000;
        line-height: 34px;
      }
      .mobileBox {
        padding: 20px;

        .ant-collapse-header {
          font-size: 0.36rem;
          font-weight: 600;
        }
        .ant-collapse-item-active {
          background: #347eff !important;
          .ant-collapse-header {
            color: #ffffff !important;
          }
        }
        .ant-collapse-item {
          background: #ffffff;
        }
        .ant-tabs-tab {
          .tab1,
          .tab2 {
            img {
              margin-right: 8px;
            }
          }
        }
        .ant-collapse {
          border: none;
          .ant-collapse-item {
            border: none;
            border-radius: 0.08rem;
          }
        }
      }
      .mobileInfo {
        margin-top: 70px;
        background: #ffffff;
        height: 374px;
        .infoBox {
          padding: 0 20px;
          .title {
            font-size: 18px;
            font-weight: 500;
            color: #000000;
            line-height: 25px;
            position: relative;
            padding-top: 50px;
            padding-left: 0.16rem;
            margin: 50px 0 0 10px;
            margin-bottom: 40px;
            &::before {
              position: absolute;
              content: "";
              width: 0.06rem;
              height: 18px;
              background: #337dff;
              top: 54px;
              left: -10px;
            }
          }
          div {
            font-size: 18px;
            font-weight: 600;
            color: #000000;
            line-height: 22px;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            color: #777777;
            line-height: 22px;
            margin-top: 8px;
          }
        }
      }
    }

    button {
      background: #347eff;
      border-radius: 0.08rem;
      border: none;
      span {
        color: #ffffff;
      }
    }
  }
}
