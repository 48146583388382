.mobileCommonBehavior {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #f8f9fc;
    margin: 0 auto;
    .behaviorBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0.3rem;
        height: auto;
        padding: 0;
        .behaviorContent{
            height: auto;
        }
    } 
}