.mobileShowBoxCom {
    flex-wrap: wrap;
    padding: 0 0.4rem;

    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .topImg {
            width: 2.8rem;
            height: 2.8rem;
        }

        .botContent {
            width: 100%;
            background-color: #f8f9fc;
            //border-radius: 0.2rem;
            padding: 0.6rem 1rem;
            margin-top: 0.25rem;
            height: auto !important;

            .titleName {
                font-size: 0.36rem;
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                text-align: center;
                color: #1f4d87;
                line-height: 0.28rem;
                border-bottom: 5px solid rgba(52, 126, 255, 0.2);
                width: 2rem;
                margin: 0 auto;
            }

            .titleContent {
                padding-top: 0.4rem;
                font-size: 0.28rem;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.85);
                line-height: 0.44rem;
            }
        }
    }
}