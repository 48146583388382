@import "../../assets/css/common";

.andLogistics{
    .andLogisticsSubHeader {
        @include boxSize(100%, 4.8rem);
        @include backgroundImageSetting("../../assets/images/andLogistics/headerBg.png");
        margin-top: -.6rem;
        position: relative;
        padding-top: 1.82rem;
        .subHeaderRoot {
            @include boxSize(100%, auto);
            text-align: center;
            .andLogisticsProductName {
                @include fontStyle(#ffffff, .48rem);
                font-weight: 600;
                letter-spacing: 1px;
                margin-bottom: 1px;
            }
            .andLogisticsDescribe {
                @include fontStyle(#ffffff, .24rem);
                letter-spacing: 1px;
                margin-bottom: .5rem;
            }
            button{
                @include fontStyle(#ffffff, .18rem);
                width: 1.34rem;
                margin: 0;
                padding: 0;
            }
            .consultButton {
                border: 1px solid #ffffff;
                border-radius: 2px;
                background-color: transparent;
                margin-left: .2rem;
            }
        }
    }
    .box-content {
        margin-bottom: 120px;
        .firstSection {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            margin-top: 60px;

            .firstTitle {
                margin-bottom: 16px;
            }

            .headerTitle {
                font-size: 34px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #000000;
                line-height: 48px;
            }

            .subTitle {
                font-size: 16px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: #74798c;
                line-height: 24px;
                text-align: center;
                max-width: 1160px;
            }

            .programImg {
                padding: 60px 0px;
            }

            .boxContent {
                display: flex;
                // width: 70%;
                // justify-content: space-between;
                justify-content: center;
                padding-top: 60px;

                .box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 50px;

                    .topImg {
                        width: 180px;
                        height: 180px;
                    }

                    .botContent {
                        width: 240px;
                        height: 245px;
                        background: #f8f9fc;
                        border-radius: 2px;
                        padding: 30px;
                        margin-top: 25px;

                        .titleName {
                            font-size: 18px;
                            font-family: PingFangSC, PingFangSC-Semibold;
                            font-weight: 600;
                            text-align: center;
                            color: #1f4d87;
                            line-height: 14px;
                            border-bottom: 5px solid rgba(52, 126, 255, 0.2);
                            width: 100px;
                            margin: 0 auto;
                        }

                        .titleContent {
                            padding-top: 15px;
                            font-size: 14px;
                            font-family: PingFangSC, PingFangSC-Regular;
                            font-weight: 400;
                            text-align: justify;
                            color: rgba(0, 0, 0, 0.85);
                            line-height: 22px;
                        }
                    }
                }
            }
        }

        .secSection {
            background-color: rgba(248, 249, 252, 1);
            padding-top: 60px;
            margin-top: 120px;

            .content {
                position: relative;
                height: 580px;
                width: 100%;
                padding-top: 40px;
                display: flex;

                .leftContent {
                    position: absolute;
                    right: 51%;
                    cursor: pointer;

                    .box {
                        width: 560px;
                        height: 82px;
                        background: #ffffff;
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        padding: 0px 20px;
                        margin-bottom: 24px;

                        &:hover {
                            background: #347eff;
                            box-shadow: 0px 0px 20px 0px rgba(14, 19, 76, 0.2);


                            .leftName {
                                color: #ffffff;

                                .point {
                                    background: #ffffff;
                                }
                            }

                            .rightTitle {
                                color: #ffffff;
                            }
                        }

                        .leftName {
                            font-size: 16px;
                            font-family: PingFangSC, PingFangSC-Semibold;
                            font-weight: 600;
                            text-align: left;
                            color: rgba(0, 0, 0, 0.85);
                            width: 160px;

                            .point {
                                display: inline-block;
                                width: 10px;
                                height: 10px;
                                background: #1890ff;
                                border-radius: 10px;
                                line-height: 20px;
                                margin: 0 20px 0 0;
                            }
                        }

                        .rightTitle {
                            font-size: 14px;
                            font-family: PingFangSC, PingFangSC-Regular;
                            font-weight: 400;
                            text-align: left;
                            color: rgba(0, 0, 0, 0.65);
                            line-height: 22px;
                            width: calc(100% - 160px);
                        }
                    }
                }

                .rightImg {
                    width: 48%;
                    position: absolute;
                    right: 0;
                }

                .rightProgramImg {
                    width: 730px;
                    height: 603px;
                    position: absolute;
                    bottom: 0;
                    right: 130px;
                }
            }
        }
    }
}
