@import "../../assets/css/common";
.mobileNewsDetails {
    .box-content {
        padding: 0 20px;
        .image-wrap{
            display: flex;
            justify-content: center;
            img {
                object-fit: contain;
                height: 100%;
            }
        }
        img {
            object-fit: contain;
            height: 100%;
        }
    }
}