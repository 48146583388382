@import "../../assets/css/common";
.mobileAndTracking {
  margin-top: 0;
  .andTrackingHeader {
    height: 7.72rem;
    padding: 1rem .58rem 0;
    .subHeaderRoot {
      display: flex;
      flex-direction: column;
      align-items: center;
      .andTrackingName {
        margin-bottom: .48rem;
        white-space: pre-wrap;
      }
      .andTrackingDescribe {
        font-size: .32rem;
        margin-bottom: .76rem;
      }
      button{
        font-size: .3rem;
        height: .82rem !important;
        width: 2.82rem;
      }
      .consultButton {
        margin-left: 0;
        margin-top: .3rem;
      }
    }
  }
  .andTracking-content{
    .firstSection{
      @include boxSize(100%, auto);
      background: #ffffff;
      padding: .6rem .42rem 0;
      .headerTitle {
        padding-bottom: 0;
      }
      .supervisionSystem{
        display: flex;
        flex-direction: column;
        justify-content: center;
        object-fit: contain;
        .describe{
          margin: 0 auto 1.3rem;
          height: 100%;
          width: 100%;
          .text{
            padding: .24rem .1rem .9rem .2rem;
            font-size: 16px;
            text-align: justify;
            color: rgba(0,0,0,0.85);
            line-height: .42rem;
            letter-spacing: .5px;
          }
          .quotationMarkRight{
            float: right;
            margin-top: -.43rem;
          }
        }
      }
    }
    .secondSection{
      @include boxSize(100%, auto);
      padding-top: .6rem;
      padding-bottom: 1.21rem;
      text-align: center;
      .headerTitle {
        @include flexCenter();
        @include fontStyle(#000000, .34rem);
        font-weight: 500;
      }
      .businessStructure{
        width: 7rem;
        height: 100%;
      }
    }
  }
}
