.commonAnstarSafety {
    display: flex;
    justify-content: space-between;
    // padding-top: 60px;
    width: 100%;
    max-width: 1160px;

    .leftTitleInfo {
        .commonHeaderTitle {
            font-size: 30px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            text-align: left;
            color: #242525;
            line-height: 42px;
            padding-bottom: 5px;
        }

        .leftBottomInfo {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .leftDetailInfo {
                margin-top: 30px;

                .leftName {
                    background: #347eff;
                    border-radius: 22px;
                    font-size: 16px;
                    font-family: PingFangSC, PingFangSC-Semibold;
                    font-weight: 600;
                    text-align: left;
                    color: #ffffff;
                    padding: 2px 23px;
                }

                .leftTitle {
                    font-size: 14px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    color: #242525;
                    line-height: 22px;
                    // width: 420px;
                    width: 80%;
                    margin-top: 15px;
                }
            }
        }
    }

    .rightImg {
        height: 492px;
        // width: 660px;
    }

}