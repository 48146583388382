@import "../../assets/css/common";

.mobileCommonLeftDrawer {
    position: relative;
    height: auto;
    width: 100%;
    padding-top: 40px;
    display: flex;

    .leftContent {
        z-index: 2;
        position: relative;
        right: 0;
        width: 100%;

        .box {
            height: auto;
            width: calc(100% - 0.8rem);
            background: #ffffff;
            border-radius: 2px;
            display: flex;
            align-items: flex-start;
            padding: 0.4rem;
            margin: 0 auto 0.48rem auto;
            flex-direction: column;
            justify-content: center;

            &:hover {
                background: #ffffff;
                box-shadow: none;
                color: rgba(0, 0, 0, 0.85);

                .productItemActiveBg1 {
                    display: none;
                }

                .productItemActiveBg2 {
                    display: none;
                }

                .leftName {
                    color: rgba(0, 0, 0, 0.85);

                    .point {
                        background: #1890ff;
                    }
                }

                .rightTitle {
                    color: rgba(0, 0, 0, 0.85);
                }
            }

            .point {
                width: 0.10rem;
                height: 0.1rem;
                background: #1890ff;
                border-radius: 0.2rem;
                line-height: 0.20rem;
                margin: 0 auto;
            }

            .leftName {
                font-size: 0.36rem;
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                text-align: left;
                color: rgba(0, 0, 0, 0.85);
            }

            .rightTitle {
                font-size: 0.28rem;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.85);
                line-height: 0.40rem;
                width: 100%;
                padding: 0.2rem 0 0 0.6rem;
            }
        }
    }

    .rightImg {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 5rem;
    }
}