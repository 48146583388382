@import "../../assets/css/common";

.commonLeftDrawer {
    position: relative;
    height: 580px;
    width: 100%;
    padding-top: 40px;
    display: flex;

    .leftContent {
        position: absolute;
        right: 51%;
        cursor: pointer;
        z-index: 10;

        .box {
            width: 560px;
            height: 82px;
            background: #ffffff;
            border-radius: 2px;
            display: flex;
            align-items: center;
            padding: 0px 20px;
            margin-bottom: 24px;
            position: relative;

            //&:hover {
            //    background: #347eff;
            //    box-shadow: 0px 0px 20px 0px rgba(14, 19, 76, 0.2);
            //    border-radius: 4px;
            //
            //    .productItemActiveBg1 {
            //        @include boxSize(1.56rem, 100%);
            //        @include leftTop(0, 0);
            //        z-index: 2;
            //        position: absolute;
            //        background-color: #236ae5;
            //        border-top-right-radius: .5rem;
            //        border-bottom-right-radius: .5rem;
            //    }
            //    .productItemActiveBg2{
            //        @include boxSize(1.63rem, 100%);
            //        z-index: 1;
            //        position: absolute;
            //        background-color: #2d75f5;
            //        border-top-right-radius: .5rem;
            //        border-bottom-right-radius: .5rem;
            //    }
            //
            //    .leftName {
            //        color: #ffffff;
            //
            //        .point {
            //            background: #ffffff;
            //        }
            //    }
            //
            //    .rightTitle {
            //        color: #ffffff;
            //    }
            //}

            .leftName {
                font-size: 16px;
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                text-align: left;
                color: rgba(0, 0, 0, 0.85);
                width: 180px;
                z-index: 3;
                .point {
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    background: #1890ff;
                    border-radius: 10px;
                    line-height: 20px;
                    margin: 0 20px 0 0;
                }
            }

            .rightTitle {
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                text-align: left;
                color: rgba(0, 0, 0, 0.65);
                line-height: 22px;
                width: calc(100% - 180px);
            }
        }

        .boxActive{
            background: #347eff;
            box-shadow: 0px 0px 20px 0px rgba(14, 19, 76, 0.2);
            border-radius: 4px;

            .productItemActiveBg1 {
                @include boxSize(1.56rem, 100%);
                @include leftTop(0, 0);
                z-index: 2;
                position: absolute;
                background-color: #236ae5;
                border-top-right-radius: .5rem;
                border-bottom-right-radius: .5rem;
            }
            .productItemActiveBg2{
                @include boxSize(1.63rem, 100%);
                z-index: 1;
                position: absolute;
                background-color: #2d75f5;
                border-top-right-radius: .5rem;
                border-bottom-right-radius: .5rem;
            }

            .leftName {
                color: #ffffff;

                .point {
                    background: #ffffff;
                }
            }

            .rightTitle {
                color: #ffffff;
            }
        }
    }

    .rightImg {
        width: 48%;
        position: absolute;
        right: 0;
        bottom: 0;
        //width: 730px;
        height: 550px;
        // object-fit: cover;
        object-fit: contain;
    }
}