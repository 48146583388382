@import "../../assets/css/common";
.compassProduct {
    @include boxSize(100%, auto);
    margin-top: -.6rem;
    overflow: hidden;
    position: relative;
    .compassProductSubHeader {
        @include boxSize(100%, 4.8rem);
        @include backgroundImageSetting("../../assets/images/compass/subCompassBg.png");
        position: relative;
        padding-top: 1.82rem;
        .subHeaderRoot {
            @include boxSize(100%, auto);
            text-align: center;
            .compassProductName {
                @include fontStyle(#ffffff, .48rem);
                font-weight: 600;
                letter-spacing: 1px;
                margin-bottom: 1px;
            }
            .compassProductDescribe {
                @include fontStyle(#ffffff, .24rem);
                letter-spacing: 1px;
                margin-bottom: .5rem;
            }
            button{
                @include fontStyle(#ffffff, .18rem);
                width: 1.34rem;
                margin: 0;
                padding: 0;
            }
            .consultButton {
                border: 1px solid #ffffff;
                border-radius: 2px;
                background-color: transparent;
                margin-left: .2rem;
            }
        }
    }
    .pdRoot {
        @include boxSize(100%, auto);
        .pd {
            @include boxSize(100%, 100%);
            max-width: $maxWidth;
            margin: 0 auto;
            position: relative;
            padding-top: .6rem;
            .pdTitle {
                @include fontStyle(#000000, .34rem);
                font-weight: 500;
                text-align: center;
                margin-bottom: .3rem;
            }
            .pdTopLeftIcon {
                @include boxSize(100%, .4rem);
                margin-bottom: .24rem;
                img{
                    @include boxSize(.52rem, .4rem);
                }
            }
            .pdCenter {
                @include boxSize(100%, auto);
                min-height: .84rem;
                margin-bottom: .24rem;
                position: relative;
                img{
                    @include boxSize(.84rem, .84rem);
                    @include rightTop(-.42rem, -.18rem);
                    position: absolute;
                }
                span{
                    @include fontStyle(rgba(0,0,0,0.85), .16rem);
                    text-align: justify;
                    letter-spacing: 0.5px;
                }
            }
            .pdBottom {
                @include boxSize(100%, auto);
                position: relative;
                display: flex;
                justify-content: space-between;
                .pdImg {
                    @include boxSize(6.1rem, 2.98rem);
                    object-fit: contain;
                }
                .pdBottomText {
                    @include boxSize(calc(100% - 6.7rem), auto);
                    @include fontStyle(rgba(0,0,0,0.85), .16rem);
                    text-align: justify;
                    letter-spacing: 2px;
                }
                .rightBottomIcon {
                    position: absolute;
                    @include boxSize(.52rem, .4rem);
                    @include rightBottom(-.79rem, .46rem);
                    transform: rotatez(180deg);
                }
            }
        }
    }
    .productAdvantages {
        @include boxSize(100%, auto);
        padding: .6rem 0 1.2rem;
        .productAdvantagesName {
            @include boxSize(100%, auto);
            @include fontStyle(#000000, .34rem);
            max-width: $maxWidth;
            font-weight: 500;
            text-align: center;
            margin: 0 auto .16rem;
        }
    }
    .commonBottomContent{
        margin-top: 0;
    }
}
