.mobileCommonAnstarSafety {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.6rem 0;
    width: calc(100% - 0.8rem);
    background: #f8f9fc;
    margin: 0 auto;

    .anstarTopTitleBtn {
        width: 6.10rem;
        height: 1.1rem;
        background: #347eff;
        border-radius: 0.55rem;
        font-size: 0.36rem;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        line-height: 1.1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.5rem;

        .circleOutlined {
            font-size: 0.53rem;
        }
    }

    .leftTitleInfo {
        width: auto !important;
        padding: 0.6rem 0.3rem;

        .commonHeaderTitle {
            font-size: 0.36rem;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            //text-align: justify;
            color: rgba(0, 0, 0, 0.85);
            line-height: 0.5rem;
        }

        .leftBottomInfo {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .leftDetailInfo {
                margin-top: 0.3rem;

                .leftName {
                    background: #347eff;
                    border-radius: 22px;
                    font-size: 0.32rem;
                    font-family: PingFangSC, PingFangSC-Semibold;
                    font-weight: 600;
                    text-align: left;
                    color: #ffffff;
                    padding: 2px 23px;
                }

                .leftTitle {
                    font-size: 0.28rem;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    color: #242525;
                    line-height: 0.4rem;
                    width: 100%;
                    margin-top: 0.3rem;
                }
            }
        }
    }

    .rightImg {
        //height: 492px;
        // width: 660px;
        width: 5.9rem !important;
        height: 3.6rem;
    }

}