@import "../../assets/css/common";

.about {
  background: #ffffff !important;

  .compass-tab {
    margin: 0 auto 60px auto;
  }

  .ant-menu-horizontal {
    text-align: center;
  }

  .compass-tab-fixed {
    position: fixed;
    width: 100%;
    top: 0;
    text-align: center;
    z-index: 9;
  }

  .aboutSubHeader {
    @include boxSize(100%, 4.8rem);
    @include backgroundImageSetting("../../assets/images/about/Group.jpg");
    margin-top: -0.6rem;
    position: relative;
    padding-top: 1.82rem;

    .subHeaderRoot {
      @include boxSize(100%, auto);
      text-align: center;

      .compassProductName {
        @include fontStyle(#ffffff, 0.48rem);
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 1px;
      }

      .compassProductDescribe {
        @include fontStyle(#ffffff, 0.24rem);
        letter-spacing: 1px;
        margin-bottom: 0.5rem;
      }

      button {
        @include fontStyle(#ffffff, 0.18rem);
        width: 1.34rem;
        margin: 0;
        padding: 0;
      }

      .consultButton {
        border-radius: 2px;
        margin-left: 0.2rem;
        background: #347eff;
        color: #ffffff;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        border: none;
      }
    }
  }

  .box-content {
    .ant-menu-item {
      a {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        color: #000000;
        letter-spacing: 0px;
      }
    }

    .top {
      display: inline-block;
      text-align: left;

      .border {
        margin-right: 10px;
        float: left;
        width: 4px;
        height: 20px;
        background: #337dff;
      }

      .title {
        font-size: 20px;
        font-weight: 500;
        color: #000000;
        line-height: 18px;
        float: left;
      }
    }

    .marginTop{
      margin-top: 30px;
    }

    .intr,
    .qual {
      max-width: 1200px;
      margin: 0 auto;
      margin-bottom: 40px;
    }

    .contentBox {
      text-align: justify;
      display: flex;
      justify-content: space-between;
    }

    .intr {
      .contentBox {
        margin: 30px 0 120px 0;

        .intr-content {
          width: 550px;
        }

        .imgBox {
          width: 510px;
          height: 224px;
          background: #eeeae7;

          .intr-img {
            img {
              width: 299px;
              height: 224px;
              margin: 0 auto;
              display: block;
            }
          }
        }
      }
    }

    .culture {
      height: 554px;
      background: #f8f9fc;

      .box {
        max-width: 1200px;
        margin: 0 auto;
        padding-top: 60px;
      }

      .contentBox {
        margin-top: 40px;
        display: flex;

        .itemBox {
          .top {
            width: 340px;
            height: 120px;
            background: #337dff;
            line-height: 120px;
            text-align: center;
            border-radius: 4px 4px 0 0;
          }

          .bottom {
            width: 340px;
            height: 186px;
            background: #ffffff;
            border-radius: 4px;
            text-align: center;
            border-radius: 0 0 4px 4px;

            div {
              font-size: 18px;
              font-weight: 600;
              color: #1f4d87;
              line-height: 24px;
              padding: 40px 0 16px 0;
              position: relative;

              &::after {
                position: absolute;
                content: "";
                width: 60px;
                height: 5px;
                opacity: 0.2;
                background: #347eff;
                bottom: 15px;
                //left: 140px;
                left: 50%;
                transform: translate(-50%, 0);
              }
            }

            p {
              color: rgba(0, 0, 0, 0.85);
              line-height: 22px;
              width: 240px;
              margin: 0 auto;
              text-align: justify;
            }
          }

          &:nth-child(3) {
            p {
              text-align: center;
            }
          }
        }
      }
    }

    .picture {
      .topBox {
        max-width: 1200px;
        margin: 60px auto 39px auto;
      }

      .contentBox {
        position: relative;

        .switchPic {
          .left {
            left: 40px;
          }

          .right {
            right: 40px;
          }

          .left,
          .right {
            position: absolute;
            top: 187px;
            z-index: 100;
            cursor: pointer;
            // background-color: #337dff;
            width: 60px;
            height: 60px;
            border-radius: 60px;
            padding: 19px;
            position: absolute;
            top: 187px;
            z-index: 100;
            cursor: pointer;
            background-color: #FFFFFF;
            opacity: 0.3;

            // &:hover {
            //   opacity: 1;
            //   box-shadow: 0px 4px 20px 0px rgba(14, 19, 76, 0.8);
            //   background-color: #FFFFFF;
            //   transition: 0.2s;
            // }
          }
        }

        .picture-ul {
          list-style: none;
          height: 420px;
          display: flex;
          padding: 0;
          overflow: hidden;
          transition: margin 1s linear;

          li {
            img {
              width: 634px;
              height: 420px;
            }
          }
        }

        &:hover {
          .switchPic {
            .left, .right {
              opacity: 1;
              box-shadow: 0px 4px 20px 0px rgba(14, 19, 76, 0.8);
              background-color: #FFFFFF;
              transition: 0.2s;
            }
          }
        }
      }
    }

    .partner {
      margin-top: 115px;
      height: 788px;
      background: #f8f9fc;

      .box {
        max-width: 1200px;
        margin: 0 auto;
        padding-top: 60px;

        .top {
          margin-bottom: 40px;
        }

        .contentBox {
          margin-bottom: 20px;

          .rowBox {
            img {
              border-radius: 4px;
              box-shadow: 0px 0px 10px 0px rgba(3, 6, 23, 0.1);
            }
          }
        }
      }
    }

    .qual {
      .box {
        margin: 60px 0;

        .top {
          margin-bottom: 15px;
        }
      }

      .ant-row {
        margin: 20px 0 !important;
        position: relative;

        .ant-col {
          padding-left: 16px;
          color: #000000;
          line-height: 20px;

          &::before {
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #337dff;
            top: 7px;
            left: -1px;
          }
        }
      }

      .titleContent{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .gradeTitle {
          padding-left: 16px;
          color: #000000;
          line-height: 20px;
          width: 50%;
          position: relative;
          line-height: 20px;
          padding-top: 15px;
          font-size: 14px;

          &::before {
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #337dff;
            top: 22px;
            left: -1px;
          }
        }
      }
    }

    .contact {
      height: 732px;
      background: #f8f9fc;

      .box {
        max-width: 1200px;
        height: 732px;
        margin: 0 auto;
        padding-top: 60px;

        .contentBox {
          height: 484px;
          max-width: 1160px;
          // background: #ffffff;
          border: 1px solid #ebedf1;
          margin-top: 40px;
          padding: 40px;
          background: url('../../assets/images/about/BG.png');
          background-size: cover;
          // border-radius: 6px;
          box-shadow: 0px 4px 20px 0px rgba(14, 19, 76, 0.2);

          .left {
            div {
              font-size: 16px;
              font-weight: 600;
              color: #ffffff;
              line-height: 24px;
              margin-bottom: 10px;

              img {
                margin-left: 10px;
                width: 16px;
                height: 16px;
              }
            }

            p {
              color: #ffffff;
              line-height: 21px;
              margin-bottom: 24px;
            }
          }

          .right {
            img {
              width: 690px;
              height: 404px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}