@import "../../assets/css/common";
.mobileCompass {
    margin-top: 0;
    .compassProductSubHeader {
        height: 6.66rem;
        padding: 1rem .6rem 0 !important;
        .subHeaderRoot {
            display: flex;
            flex-direction: column;
            align-items: center;
            .compassProductName {
                margin-bottom: .48rem;
                white-space: pre-wrap;
            }
            .compassProductDescribe {
                font-size: .32rem;
                margin-bottom: .76rem;
            }
            button{
                font-size: .3rem;
                height: .82rem !important;
                width: 2.82rem;
            }
            .consultButton {
                margin-left: 0;
                margin-top: 0;
            }
        }
    }
    .pdRoot {
        .pd {
            max-width: 100%;
            padding: 1rem .4rem 0;
            .pdTitle {
                font-size: .48rem;
                margin-bottom: .8rem;
            }
            .pdTopLeftIcon {
                height: .51rem;
                margin-bottom: .29rem;
                img{
                    @include boxSize(.66rem, .51rem);
                }
            }
            .pdCenter {
                margin-bottom: .4rem;
                display: flex;
                flex-direction: column;
                span{
                    font-size: .28rem;
                    padding: 0 .2rem;
                    letter-spacing: 0;
                }
                .pdCenterImgRoot {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    .wavePointPNG{
                        position: relative;
                        @include boxSize(1.34rem, 1.34rem);
                        margin-top: -0.5rem;
                        right: 0;
                        top: 0;
                    }
                    .rightBottomIcon {
                        position: relative;
                        @include boxSize(.66rem, .51rem);
                        transform: rotatez(180deg);
                        right: 0;
                        top: 0;
                    }
                }
            }
            .pdBottom {
                .pdImg {
                    @include boxSize(100%, auto);
                }
            }
        }
    }
    .productAdvantages {
        padding: .8rem 0 1.2rem;
        .productAdvantagesName {
            font-size: .48rem;
            max-width: 100%;
            margin-bottom: .8rem;
        }
    }
}
