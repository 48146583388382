@import "../../assets/css/common";
.mobileCommonProgramAns{
  .programAnsHeader {
    @include boxSize(100%, 4rem);
    @include backgroundImageSetting("../../assets/images/programAns/programAnsBg.png");
    margin-top: 0;
    position: relative;
    padding-top: 1rem;
    .subHeaderRoot {
      @include boxSize(100%, auto);
      text-align: center;
      .programAnsName {
        @include fontStyle(#ffffff, .48rem);
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 1px;

      }
      .programAnsDescribe {
        @include fontStyle(#ffffff, .32rem);
        letter-spacing: 1px;
        margin-bottom: .5rem;
      }
    }
  }
  .programAnsForm{
    margin: -60px auto 150px;
  }
  .affirmModal{
    .affirmContent{
      .consultButton{
        width: 1.34rem;
        line-height: .28rem;
        border: 1px solid #347eff;
        border-radius: 2px;
        font-size: .18rem;
        color: #2e7aff;
        letter-spacing: .6px;
        margin-top: .3rem;
      }
    }
  }
}
