@import "../../assets/css/common";

.home {
  @include boxSize(100%, auto);
  position: relative;
  overflow: hidden;
  scroll-behavior: smooth;

  .homeSubHeader {
    @include boxSize(100%, 5.8rem);
    position: relative;

    .homeCarouselRoot {
      @include boxSize(100%, 100%);
      position: relative;

      .carouselItem {
        @include boxSize(100%, 5.8rem);
        background-color: #002648;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;

        .carouselItemContent {
          @include boxSize(100%, 100%);
          max-width: $maxWidth;
          margin: 0 auto;
          padding-top: 1.6rem;

          div:nth-child(1) {
            @include boxSize(100%, 0.67rem);
            @include fontStyle(#ffffff, 0.48rem);
            font-weight: 600;
            letter-spacing: 1px;
            display: flex;
            align-items: center;
            margin-bottom: 0.3rem;
          }

          div:nth-child(2),
          div:nth-child(3) {
            @include boxSize(100%, 0.48rem);
            @include fontStyle(#ffffff, 0.24rem);
            letter-spacing: 1px;
            display: flex;
            align-items: center;
            margin-bottom: 4px;
          }

          div:nth-child(3) {
            margin-bottom: 0.5rem;
          }

          .ant-btn {
            @include boxSize(1.34rem, 0.41rem);
            @include fontStyle(#ffffff, 0.18rem);
            border: 1px solid #ffffff;
            border-radius: 2px;
            background-color: transparent;
          }
          .clickBtn {
            @include boxSize(2.9rem, 0.8rem);
            background: #347eff;
            border-radius: 0.4rem;
            border: none;
            font-size: 48px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            text-align: center;
            color: #ffffff;
          }
        }

        .centerItemContent {
          text-align: center;
          div {
            justify-content: center;
          }
        }
      }

      .left,
      .right {
        width: 22px;
        height: 39px;
        position: absolute;
        top: 300px;
        z-index: 19;
        cursor: pointer;
        opacity: 0.3;

        &:hover {
          opacity: 1;
          width: 45px;
          height: 45px;
          border-radius: 45px;
          padding: 10px;
          background: #ffffff;
          transition: 0.2s;
        }
      }

      .left {
        left: 40px;
        &:hover {
          left: 30px;
        }
      }

      .right {
        right: 40px;
        &:hover {
          right: 30px;
        }
      }

      .slick-dots {
        li {
          @include boxSize(50px, 4px);
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }

        li button {
          @include boxSize(50px, 4px);
          background-color: #ffffff;
          opacity: 0.2;
          border-radius: 0;
        }

        li.slick-active button {
          background-color: #1890ff;
          opacity: 1;
        }
      }
    }
  }

  .homeDivRoot {
    @include boxSize(100%, auto);
    padding: 0.6rem 0 1.2rem;

    .homeDiv {
      @include boxSize(100%, auto);
      max-width: $maxWidth;
      margin: 0 auto;

      .homeDivTitle {
        @include boxSize(100%, 0.48rem);
        @include flexCenter();
        @include fontStyle(#000000, 0.34rem);
        font-weight: 500;
        margin-bottom: 0.6rem;
      }

      .latestNewsRoot {
        @include boxSize(100%, auto);
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.6rem;

        .latestNewsItem {
          @include boxSize(3.4rem, auto);
          background-color: #f8f9fc;
          border-radius: 4px;
          overflow: hidden;

          .latestNewsItemHeader1,
          .latestNewsItemHeader2,
          .latestNewsItemHeader3 {
            @include boxSize(100%, 2.2rem);
            background-size: cover;
            background-repeat: no-repeat;
            padding-top: 0.7rem;

            .latestNewsItemHeaderName {
              @include boxSize(100%, 0.5rem);
              @include flexCenter();
              @include fontStyle(#ffffff, 0.36rem);
              text-align: center;
              font-weight: 600;
              letter-spacing: 1px;
              text-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
              margin-bottom: 0.52rem;
            }

            .latestNewsItemHeaderButton {
              @include boxSize(0.9rem, 0.32rem);
              @include flexCenter();
              @include fontStyle(rgba(0, 0, 0, 0.85), 0.14rem);
              margin: 0 auto;
              background-color: #ffffff;
              border-radius: 0.18rem;
            }
          }

          .latestNewsItemHeader1 {
            background-image: url("../../assets/images/home/Group 10.png");
          }

          .latestNewsItemHeader2 {
            background-image: url("../../assets/images/home/Group 11.png");
          }

          .latestNewsItemHeader3 {
            background-image: url("../../assets/images/home/Group 12.png");
          }

          .newsCardRoot {
            @include boxSize(100%, auto);
            min-height: 2.21rem;
            padding: 0 0.2rem 0;

            .newsCard {
              @include boxSize(100%, auto);
              min-height: 1.1rem;
              padding-top: 0.24rem;
              padding-bottom: 0.2rem;
              border-bottom: 1px solid #ebedf1;

              &:last-child {
                border-bottom: none;
              }

              .newsCardTitle {
                @include boxSize(100%, 44px);
                @include fontStyle(rgba(0, 0, 0, 0.85), 0.14rem);
                margin-bottom: 0.06rem;
                font-weight: 600;
                cursor: pointer;
              }

              .newsCardTime {
                @include boxSize(100%, auto);
                @include fontStyle(#74798c, 0.14rem);
              }
            }
          }
        }
      }

      .homeNewsButton {
        @include boxSize(1.46rem, 0.4rem);
        @include flexCenter();
        @include fontStyle(#ffffff, 0.14rem);
        margin: 0 auto;
        border-radius: 0.2rem;
        background-color: #347eff;
      }

      .coreCompetenceRoot {
        @include boxSize(100%, auto);
        display: flex;
        justify-content: space-between;

        .coreCompetence {
          @include boxSize(3.4rem, auto);
          min-height: 3.91rem;
          text-align: center;

          .coreCompetenceImgRoot {
            @include boxSize(100%, 2.15rem);
            text-align: center;

            img {
              @include boxSize(200px, 200px);
              object-fit: contain;
            }
          }

          .coreCompetenceBottom {
            @include boxSize(100%, auto);
            min-height: 1.99rem;
            background-color: #ffffff;
            padding: 0.3rem 50px 40px;
            border-radius: 8px;

            .coreCompetenceName {
              @include fontStyle(#1f4d87, 0.18rem);
              height: 0.25rem;
              font-weight: 600;
              display: inline-block;
              padding: 0 0.07rem;
              border-bottom: 0.05rem solid rgba(52, 126, 255, 0.2);
              margin-bottom: 0.16rem;
            }

            .coreCompetenceText {
              @include fontStyle(rgba(0, 0, 0, 0.85), 0.14rem);
              letter-spacing: 1px;
              width: 100%;
              text-align: left;
            }
          }
        }
      }

      .homeProductRoot {
        @include boxSize(100%, 3.72rem);
        display: flex;
        justify-content: space-between;

        .productLeft {
          @include boxSize(2.4rem, 100%);
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .productItem {
            @include boxSize(100%, 0.62rem);
            @include flexAlignCenter();
            @include fontStyle(rgba(0, 0, 0, 0.85), 0.16rem);
            font-weight: 600;
            position: relative;
            padding-left: 0.5rem;
            cursor: pointer;

            span {
              position: relative;
              z-index: 3;
            }

            &:before {
              content: "";
              position: absolute;
              @include leftTop(0.2rem, 50%);
              @include boxSize(0.1rem, 0.1rem);
              z-index: 3;
              transform: translateY(-50%);
              border-radius: 50%;
              background-color: #347eff;
            }
          }

          .productItemActive {
            background-color: #347eff;
            color: #ffffff;
            border-radius: 4px;
            box-shadow: 0 0 20px 0 rgba(14, 19, 76, 0.2);
            overflow: hidden;

            &:before {
              content: "";
              background-color: #ffffff;
            }

            .productItemActiveBg1 {
              @include boxSize(1.83rem, 150%);
              @include leftTop(0, -25%);
              z-index: 2;
              position: absolute;
              background-color: #236ae5;
              border-top-right-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
            }

            .productItemActiveBg2 {
              @include boxSize(2.11rem, 150%);
              @include leftTop(0, -25%);
              z-index: 1;
              position: absolute;
              background-color: #2d75f5;
              border-top-right-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
            }
          }
        }

        .productRight {
          @include boxSize(calc(100% - 3rem), 100%);
          background-color: #f8f9fc;
          border-radius: 4px;
          padding: 0.5rem 0.5rem 0.4rem;
          position: relative;

          .productRightName {
            @include boxSize(100%, 0.22rem);
            @include flexAlignCenter();
            @include fontStyle(rgba(0, 0, 0, 0.85), 0.18rem);
            font-weight: 600;
            justify-content: space-between;
            margin-bottom: 0.2rem;
          }

          .productRightDescribe,
          .productRightCase {
            @include fontStyle(rgba(0, 0, 0, 0.85), 0.14rem);
            padding-right: 0.38rem;
            margin-bottom: 0.3rem;
            text-align: justify;
          }

          .productRightCase {
            margin-bottom: 0;
          }

          .productRightButton {
            position: absolute;
            @include boxSize(1.46rem, 0.4rem);
            @include flexCenter();
            @include leftBottom(0.5rem, 0.4rem);
            @include fontStyle(#ffffff, 0.14rem);
            background-color: #347eff;
            cursor: pointer;
            border-radius: 0.2rem;
          }

          .productRightIcon {
            position: absolute;
            @include rightBottom(0.5rem, 0.4rem);
          }
        }
      }

      .partnersRoot {
        @include boxSize(100%, auto);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        img {
          margin-bottom: 0.2rem;
          object-fit: contain;
          border-radius: 4px;
          box-shadow: 0 0 10px 0 rgba(3, 6, 23, 0.1);
        }
      }
    }
  }

  .homeBottomRoot {
    @include boxSize(100%, 2.31rem);
    @include backgroundImageSetting("../../assets/images/home/homeBottomBg.png");

    .homeBottom {
      @include boxSize(100%, 100%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: $maxWidth;
      margin: 0 auto;
      padding: 0.8rem 0;

      .homeBottomLeft {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        span:nth-child(1) {
          @include fontStyle(#ffffff, 0.24rem);
          font-weight: 600;
        }

        span:nth-child(2) {
          @include fontStyle(#ffffff, 0.16rem);
        }
      }

      .ant-btn {
        @include boxSize(1.34rem, 0.41rem);
        @include fontStyle(#ffffff, 0.18rem);
        border: 1px solid #ffffff;
        border-radius: 2px;
        background-color: transparent;
      }
    }
  }
}
