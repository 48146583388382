@import "../../../assets/css/common";
.commonDivRoot {
    @include boxSize(100%, auto);
    padding: .6rem 0 1.2rem;
    .commonDiv {
        @include boxSize(100%, auto);
        max-width: $maxWidth;
        margin: 0 auto;
    }
    .commonTitle {
        @include boxSize(100%, .48rem);
        @include flexCenter();
        @include fontStyle(#000000, .34rem);
        font-weight: 500;
    }
    .commonDescribe {
        @include boxSize(100%, auto);
        @include flexCenter();
        @include fontStyle(rgba(0,0,0,0.85), .16rem);
        word-break: break-all;
        white-space: pre-wrap;
        word-wrap: break-word;
        margin-top: .4rem;
    }
    .showBoxCom .box .botContent{
        text-align: center;
    }
    .showBoxCom .box .botContent .titleName{
        display: inline-block;
        width: auto;
    }
    .operationFlowImgRoot{
        @include boxSize(100%, auto);
        @include flexCenter();
        padding: 0 .2rem;
        margin-top: .6rem;
        img{
            width: 100%;
            object-fit: cover;
        }
    }
    .applicationCasesRoot {
        @include boxSize(100%, auto);
        overflow: hidden;
        margin-top: .6rem;
        padding: .6rem;
        background: linear-gradient(322deg,#1a60d8 -6%, #347eff 99%);
        border-radius: 4px;
        position: relative;
        .applicationCasesRootTitle {
            @include boxSize(100%, auto);
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: .5rem;
            span{
                @include fontStyle(#ffffff, .24rem);
                font-weight: 600;
            }
            img{
                @include boxSize(.30rem, .22rem);
            }
        }
        .commonDivRootText {
            @include boxSize(100%, auto);
            @include fontStyle(#ffffff, .14rem);
            padding-left: .13rem;
            word-break: break-all;
            white-space: pre-wrap;
            word-wrap: break-word;
            text-align: justify;
        }
        .commonIconTitle {
            @include boxSize(100%, auto);
            @include fontStyle(#ffffff, .16rem);
            margin-bottom: .16rem;
            padding-left: .13rem;
            position: relative;
            font-weight: 600;
            &:before{
                content: '';
                @include boxSize(3px, .16rem);
                @include leftTop(0, 50%);
                transform: translateY(-50%);
                position: absolute;
                background-color: #ffffff;
            }
        }
        .partnerRoot {
            @include boxSize(100%, auto);
            margin-bottom: .4rem;
        }
        .lastTextRoot {
            @include boxSize(100%, auto);
        }
        &:before{
            @include boxSize(3.48rem, 3.48rem);
            @include leftBottom(-1rem, -1.74rem);
            content: '';
            position: absolute;
            background-color: rgba(255,255,255,.1);
            border-radius: 50%;
        }
        &:after{
            @include boxSize(1.58rem, 1.58rem);
            @include rightTop(-.5rem, -.7rem);
            content: '';
            position: absolute;
            background-color: rgba(255,255,255,.1);
            border-radius: 50%;
        }
    }
}
