@import "../../assets/css/common";
.mobileRichApplicationScenarios {
    height: auto;
    padding: .8rem 0 1.2rem;
    .mobileRasRoot {
        @include boxSize(100%, auto);
        padding: 0 .4rem;
        .rasHeaderTitle {
            @include fontStyle(#000000, .48rem);
            margin-bottom: .8rem;
            text-align: center;
        }
        .mobileRas {
            @include boxSize(100%, auto);
            margin-bottom: .3rem;
            .mobileRasItem {
                @include boxSize(100%, 1.11rem);
                @include flexAlignCenter();
                @include fontStyle(rgba(0,0,0,0.85), .36rem);
                justify-content: space-between;
                border-radius: .08rem;
                background-color: #ffffff;
                font-weight: 600;
                position: relative;
                padding-left: 1.4rem;
                padding-right: .38rem;
                cursor: pointer;
                .anticon {
                    color: #347eff;
                }
                span{
                    position: relative;
                    z-index: 3;
                }
                .mobileRasItemIcon {
                    position: absolute;
                    @include leftTop(.4rem, 50%);
                    @include boxSize(.7rem, .7rem);
                    z-index: 3;
                    transform: translateY(-50%);
                    border-radius: 50%;
                    background-color: #F2F3F7;
                }
                .mobileRasItemActiveBg1 {
                    @include boxSize(0, 100%);
                    @include leftTop(-20%, 0);
                    z-index: 2;
                    position: absolute;
                    background-color: #236ae5;
                    border-radius: 8rem;
                }
                .mobileRasItemActiveBg2{
                    @include boxSize(0, 100%);
                    @include leftTop(-20%, 0);
                    z-index: 1;
                    position: absolute;
                    background-color: #2d75f5;
                    border-radius: 8rem;
                }
            }
            .mobileRasItemActive {
                overflow: hidden;
                background-color: #347eff;
                color: #ffffff;
                border-radius: .08rem;
                box-shadow: 0 0 .2rem 0 rgba(14,19,76,0.2);
                .mobileRasItemIcon {
                    background-color: #347EFF;
                }
                .mobileRasItemActiveBg1 {
                    width: 97%;
                }
                .mobileRasItemActiveBg2{
                    width: 107%;
                }
                .anticon {
                    color: #ffffff;
                }
            }
            .mobileRasItemBottom {
                @include boxSize(100%, auto);
                overflow: hidden;
                background-color: #ffffff;
                padding: .6rem .4rem;
                position: relative;
                .mobileRasItemBottomName {
                    @include fontStyle(rgba(0,0,0,0.85), .36rem);
                    margin-bottom: .16rem;
                }
                .mobileRasItemBottomSynopsis {
                    @include fontStyle(rgba(0,0,0,0.85), .28rem);
                    margin-bottom: .2rem;
                }
                .mobileRasItemBottomLeftSplit {
                    @include boxSize(.48rem, .06rem);
                    background-color: #347eff;
                    margin-bottom: .4rem;
                }
                .mobileRasItemBottomDescribe {
                    @include fontStyle(rgba(0,0,0,0.85), .28rem);
                    margin-bottom: .4rem;
                }
                img{
                    width: 100%;
                    border: 1px solid #ebedf1;
                }
            }
        }
    }
}
