.mobileValueContent {
    display: flex;
    justify-content: space-around;
    padding: 0.6rem 0;
    width: 100%;
    max-width: 1160px;
    overflow: hidden;
    .valueBox {
        //width: 320px;
        // height: 459px;
        background: #f8f9fc;
        border-radius: 4px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

        width: 5.4rem;
        height: 7.6rem;
        position: relative;

        .valueImg {
            width: 100%;
            height: 4rem;
            border-radius: 4px 4px 0px 0px;
            object-fit: cover;
        }

        .introduceTitle {
            padding: 0.4rem 0.8rem;

            .subName {
                font-size: 0.36rem;
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                text-align: left;
                color: rgba(0, 0, 0, 0.85);
                line-height: 0.44rem;
                text-align: center;
            }

            .line {
                width: 0.5rem;
                height: 0.05rem;
                background: #74bcff;
                text-align: center;
                // padding: 10px 0px;
                margin: 10px auto 16px auto;
            }

            .bottomTitle {
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: rgba(0,0,0,0.65);
                line-height: 22px;
                text-align: justify;
            }
        }
    }

    .slider-decorator-0 {
        bottom: -.7rem !important;
    }
    .am-carousel-wrap-dot > span {
        display: block;
        width: .6rem;
        height: .08rem;
        margin: 0 .1rem;
        border-radius: 0;
        background: rgba(24,144,255,.2);
    }
    .am-carousel-wrap-dot-active > span{
        background-color: #1890ff;
    }
}