@import "../../assets/css/common";
.andFinance {
  @include boxSize(100%, auto);
  overflow: hidden;
  position: relative;
  margin-top: -.6rem;
  .andFinanceHeader {
    @include boxSize(100%, 4.8rem);
    @include backgroundImageSetting("../../assets/images/andFinance/banner.png");
    position: relative;
    padding: 1.82rem .2rem 0;
    .subHeaderRoot {
      @include boxSize(100%, auto);
      text-align: center;
      .andFinanceName {
        @include fontStyle(#ffffff, .48rem);
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 1px;
      }
      .andFinanceDescribe {
        @include fontStyle(#ffffff, .24rem);
        letter-spacing: 1px;
        margin-bottom: .5rem;
      }
      button{
        @include fontStyle(#ffffff, .18rem);
        width: 1.34rem;
        margin: 0;
        padding: 0;
      }
      .consultButton {
        border: 1px solid #ffffff;
        border-radius: 2px;
        background-color: transparent;
        margin-left: .2rem;
      }
    }
  }

  .andFinance-content{
    width: 100%;
    .firstSection{
      @include boxSize(100%,  auto);
      background: #ffffff;
      text-align: center;
      padding-top: .6rem;
      padding-bottom: 1.2rem;
      .headerTitle {
        @include boxSize(100%, .48rem);
        @include flexCenter();
        @include fontStyle(#000000, .34rem);
        font-weight: 500;
      }
      .subTitle {
        @include fontStyle(#74798c, .16rem);
        margin-top: .15rem;
      }
      .painSpotImg{
        padding: 0 .2rem;
        margin-top: .6rem;
        width: 11.60rem;
        object-fit: contain;
        img{
          width: 100%;
        }
      }
    }
    .secondSection{
      width: 100%;
      background: #f8f9fc;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: .6rem 0 1.2rem;
      .category-content{
        display: flex;
      }
    }
    .headerTitle {
      font-size: 34px;
      font-weight: 500;
      color: #000000;
      line-height: 48px;
    }
    .threeSection{
      width: 100%;
      height: 669px;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}
