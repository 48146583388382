@import "../../assets/css/common";
.richApplicationScenarios {
    @include boxSize(100%, 9.33rem);
    position: relative;
    background: #f8f9fc;
    .rasHeaderRoot {
        @include boxSize(100%, 3.64rem);
        .rasHeader {
            @include boxSize(100%, 100%);
            max-width: $maxWidth;
            margin: 0 auto;
            padding-top: .6rem;
            .rasHeaderTitle {
                @include boxSize(100%, .48rem);
                @include fontStyle(#000000, .34rem);
                text-align: center;
                font-weight: 500;
                margin-bottom: .6rem;
            }
            .rasHeaderItemRoot {
                @include boxSize(100%, 1.96rem);
                display: flex;
                justify-content: space-between;
                .rasHeaderItem {
                    @include boxSize(1.2rem, 100%);
                    .rasHeaderItemImgRoot {
                        @include boxSize(1rem, 1rem);
                        margin: 0 auto;
                        transition: $transitionTime all;
                        cursor: pointer;
                        border-radius: 50%;
                        background-color: #E5E7EF;
                        img{
                            @include boxSize(100%, 100%);
                        }
                    }
                    .rasHeaderItemName {
                        @include boxSize(100%, .22rem);
                        @include fontStyle(#74798C, .18rem);
                        @include flexCenter();
                        transition: $transitionTime all;
                        cursor: pointer;
                        font-weight: 600;
                        text-align: center;
                        margin: .24rem 0 .3rem;
                    }
                    .rasHeaderItemActive {
                        @include boxSize(.4rem, .4rem);
                        transition: $transitionTime all;
                        background-color: #347EFF;
                        margin: 0 auto;
                        transform: rotateZ(45deg);
                    }
                }
            }
        }
    }
    .rasBottomRoot {
        @include boxSize(100%, 5.69rem);
        background: #347eff;
        position: relative;
        overflow: hidden;
        &:before{
            @include boxSize(6rem, 9rem);
            @include rightTop(-.7rem, -.7rem);
            content: '';
            position: absolute;
            background-color: #2363D1;
            transform: rotateZ(24deg);
        }
        .rasBottom {
            @include boxSize(100%, 100%);
            position: relative;
            max-width: $maxWidth;
            margin: 0 auto;
            padding: 1.2rem 0;
            display: flex;
            justify-content: space-between;
            .rasBottomLeft {
                @include boxSize(25%, 100%);
                .rasBottomName {
                    @include boxSize(100%, auto);
                    @include flexAlignCenter();
                    @include fontStyle(#FFFFFF, .24rem);
                    font-weight: 600;
                    margin-bottom: .04rem;
                    justify-content: flex-start;
                }
                .rasBottomSynopsis {
                    @include boxSize(100%, auto);
                    @include flexAlignCenter();
                    @include fontStyle(#FFFFFF, .18rem);
                    font-weight: 600;
                    margin-bottom: .2rem;
                    justify-content: flex-start;
                }
                .rasBottomLeftSplit {
                    @include boxSize(.36rem, 4px);
                    background-color: #ffffff;
                    margin-bottom: .4rem;
                }
                .rasBottomDescribe {
                    @include boxSize(100%, auto);
                    @include fontStyle(#FFFFFF, .14rem);
                    white-space: pre-wrap;
                    text-align: justify;
                }
            }
            .rasBottomRight {
                @include boxSize(65%, 100%);
                border-radius: 4px;
                box-shadow: 0 0 20px 0 rgba(1,3,59,0.2);
                background-color: #FFFFFF;
                overflow: hidden;
                img{
                    width: 100%;
                    object-fit: cover;
                }
            }
            .rasWavePoint {
                position: absolute;
                @include boxSize(.56rem, .56rem);
                @include leftTop(-.3rem, 3.86rem);
            }
        }
    }
}
