@import "../../assets/css/common";

.mobileAnstar {
    margin-top: 0;

    .anstarSubHeader {
        height: 7.7rem;

        .subHeaderRoot {
            @include boxSize(100%, auto);
            text-align: center;

            .compassProductDescribe {
                padding: 0.48rem 0.6rem 0rem 0.6rem;
            }

            button {
                height: .82rem !important;
                width: 2.82rem;
            }

            .consultButton {
                font-size: .3rem;
            }
        }
    }

    .anstarContent {
        background-color: #ffffff;

        .firstSection {
            display: flex;
            //justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            margin-top: 0.6rem;
            &:first-child{
                margin-top: 0rem;
            }
            .headerTitle {
                font-size: 0.48rem;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #000000;
                line-height: 0.96rem;
                text-align: center;
            }

            .subTitle {
                padding: 0 0.4rem;
                font-size: 0.28rem;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                text-align: center;
                color: #74798c;
                line-height: 0.4rem;
                text-align: justify;
            }

            .imgModel {
                padding-top: 0.6rem;
                width: 6.7rem;
                //height: 3.6rem;
                object-fit: cover;
            }

            .borderContent {
                width: 94%;
                height: 1.32rem;
                border: .05rem dashed #a5d2ff;
                border-radius: 0.7rem;
                margin: 0.6rem 0 0.8rem 0;

                .safetyBox {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    align-items: center;
                    padding: 0px 0.4rem;

                    .safetyButton {
                        width: 1.7rem;
                        height: 1.1rem;
                        background-color: #F2F6FB;
                        border-radius: 0.56rem;
                        color: #347EFF;
                        text-align: center;
                        font-size: 0.28rem;
                        font-weight: 600;
                        //padding: 0.2rem;
                        padding: 0 0.2rem;
                        line-height: 0.28rem;

                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3) {
                            margin-top: -0.58rem;
                        }

                        &:nth-child(4),
                        &:nth-child(5) {
                            margin-top: 0.14rem;
                        }
                    }

                    .chooseBtn {
                        background: #347eff;
                        color: #ffffff;
                    }
                }
            }
        }

        .backgroundColor {
            background: #f8f9fc;
            padding-top: 0.6rem;
        }

        .lastHeight{
            height: 6.2rem;
        }
    }

    .bottomAnstarSafety {
        margin-top: 1.4rem;
    }
}