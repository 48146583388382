.mobileCommonFixedButton{ 
    
    .helpButton {
        position: fixed;
        right: 0.2rem;
        bottom: 0.8rem;
        cursor: pointer;
        width: 0.8rem;
        height: 0.8rem;
        background: #347eff;
        border-radius: 6px;
        color: #ffffff;
        line-height: 0.8rem;
        .helpButtonEar{
            width: 0.44rem;
            height: 0.44rem;
        }
}
.mobileHelpContent {
    //position: fixed;
    width: 4.6rem;
    height: 1.6rem;
    padding: 0.1rem;

    .helpTitle {
        font-size: 0.28rem;
        line-height: 0.4rem;
    }

    .helpType {
        font-size: 0.28rem;

        .inconStyle {
            width: 0.44rem;
            height: 0.36rem;
        }
    }

    .tips {
        font-size: 0.28rem;
        line-height: 0.4rem;
    }

    .telephone{
        display: flex;
        justify-content: space-between;
    }

    .closeCircleOutlined{
        width: 0.32rem;
        height: 0.32rem;
        background: transparent;
    }
}

    .onLineContent{
        width: 4.6rem;
        height: 3.2rem;
    }
}

