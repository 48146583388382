@import "../../assets/css/common";

.joinus {
    background: #ffffff !important;

    .joinusSubHeader {
        @include boxSize(100%, 4.8rem);
        @include backgroundImageSetting("../../assets/images/joinus/Group.jpg");
        margin-top: -0.6rem;
        position: relative;
        padding-top: 1.82rem;

        .subHeaderRoot {
            @include boxSize(100%, auto);
            text-align: center;

            .compassProductName {
                @include fontStyle(#ffffff, 0.48rem);
                font-weight: 600;
                letter-spacing: 1px;
                margin-bottom: 1px;
            }

            .compassProductDescribe {
                @include fontStyle(#ffffff, 0.24rem);
                letter-spacing: 1px;
                margin-bottom: 0.5rem;
            }

            button {
                @include fontStyle(#ffffff, 0.18rem);
                width: 1.34rem;
                margin: 0;
                padding: 0;
            }

            .consultButton {
                border-radius: 2px;
                margin-left: 0.2rem;
                background: transparent;
                color: rgba(255, 255, 255, 1);
                font-size: 18px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
            }
        }
    }

    .box-content {
        margin: 60px 0 0 0 !important;

        .joinus-intr {
            max-width: 1160px;
            margin: 0 auto;

            div {
                font-size: 34px;
                font-weight: 500;
                text-align: center;
                color: #000000;
                line-height: 48px;
                margin-bottom: 40px;
            }

            p {
                font-size: 16px;
                text-align: justify;
                color: rgba(0, 0, 0, 0.85);
                line-height: 29px;
            }
        }

        .joinus-rec {
            margin: 120px 0 0 0;
            min-height: 888px;
            background: #f8f9fc;

            .jobInfoTitle {
                font-size: 34px;
                font-weight: 500;
                text-align: center;
                color: #000000;
                line-height: 48px;
                padding-top: 40px;
            }

            .box {
                max-width: 1160px;
                margin: 0 auto;
                padding-top: 60px;
                display: flex;
                justify-content: space-between;
                
                .left {
                    width: calc(100% - 315px);
                    .tab1, .tab2 {
                        font-family: PingFangSC, PingFangSC-Regular;
                        text-align: center;
                        color: #74798c;
                        line-height: 20px;

                        img {
                            margin-right: 10px;
                        }
                    }

                    .ant-collapse-header {
                        background: #ffffff;
                        font-weight: 600;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 20px;
                    }

                    .ant-collapse > .ant-collapse-item {
                        border-bottom: 1px solid #f0f2f5;;
                    }
                    .ant-collapse-content{
                        border-top: 1px solid #f0f2f5;;
                    }
                    .leftBox {
                        p {
                            color: #777777;
                            line-height: 20px;
                            margin-bottom: 10px;
                        }

                        .tab1-btn {
                            color: #fff;
                            background: #337DFF;
                        }

                        button {
                            border: none;
                            margin: 20px 0 24px 0;
                            border-radius: 4px;
                            border: none;
                        }
                    }

                    .ant-collapse {
                        width: 847px;
                        border: 1px solid #f0f2f5;
                    }

                    .ant-tabs-ink-bar {
                        background: #337DFF;
                        width: 152px;
                        height: 3px;
                    }
                }

                .right {
                    margin: 67px 0 0 40px;
                    width: 315px;
                    .right-top {
                        margin-bottom: 30px;

                        .right-border {
                            width: 4px;
                            height: 20px;
                            background: #337dff;
                            float: left;
                            margin-right: 10px;
                        }

                        .right-title {
                            font-size: 16px;
                            font-weight: 500;
                            color: #000000;
                            line-height: 22px;
                        }
                    }

                    .right-content {
                        margin-left: 16px;

                        div {
                            font-size: 16px;
                            font-weight: 600;
                            color: #000000;
                            line-height: 24px;
                        }

                        p {
                            font-weight: 400;
                            text-align: left;
                            color: #777777;
                            line-height: 21px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}