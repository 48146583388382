@import "../../assets/css/common";

.highBoxCom {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    width: 100%;
    max-width: 1160px;

    .scenariosBox {
        width: 240px;
        height: 318px;
        border-radius: 4px;
        box-shadow: 0px 1px 14px 0px rgba(21, 23, 94, 0.1);
        position: relative;
        transition: $transitionTime all;

        &:hover {
            background: #347eff;

            .bgImg,
            .topImg {
                display: none;
            }

            .highBoxComItemName {
                .titleName {
                    background: #ffffff;
                    color: #347eff;
                }

                .blueLeft {
                    background: url("../../assets/images/andChain/whiteLeft.png") 100% 100%;
                }

                .blueRight {
                    background: url("../../assets/images/andChain/whiteLeft.png") 100% 100%;
                    z-index: 10;
                }
            }

            .mask {
                display: block;
            }
        }

        .bgImg {
            width: 100%;
            height: 100%;
        }

        .topImg {
            position: absolute;
            width: 140px;
            height: 140px;
            left: 50%;
            transform: translate(-50%, 0%);
            top: 50px;
        }

        .highBoxComItemName {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 44px;
            left: 50%;
            transform: translate(-50%, 0%);

            .titleName {
                margin: 0 4px;
                width: 60px;
                height: 26px;
                background: #347eff;
                border-radius: 1px;
                text-align: center;
                line-height: 26px;
                font-size: 16px;
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                color: #ffffff;
            }

            .blueLeft {
                background: url("../../assets/images/andChain/blueLeft.png") 100% 100% no-repeat;
                width: 20px;
                height: 22px;
            }

            .blueRight {
                transform: scaleX(-1);
                background: url("../../assets/images/andChain/blueLeft.png") 100% 100% no-repeat;
                width: 20px;
                height: 22px;
            }
        }


        .mask {
            position: absolute;
            top: 40px;
            padding: 0px 30px;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: justify;
            color: #ffffff;
            line-height: 22px;
            height: 0px;
            display: none;
        }
    }
}