.layout {
    background: #fff;
    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
        border-bottom: 0 !important;
    }

    .ant-menu-dark .ant-menu-sub {
        background: #141414 !important;
    }
    .home-menu {
        line-height: 60px !important;
    }
    .home-menu a:hover {
        color: #fff;
    }
    .header-drop {
        color: rgba(255, 255, 255, 1);
    }
    #components-layout-demo-top .logo {
        width: 120px;
        height: 31px;
        background: rgba(255, 255, 255, 0.2);
        margin: 16px 24px 16px 0;
        float: left;
    }
    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
        background-color: transparent;
    }
    .site-layout-content {
        background: #fff;
        padding: 24px;
        min-height: 280px;
    }
    .ant-card-meta-title {
        font-size: 14px !important;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600 !important;
        color: rgba(0, 0, 0, 1);
        line-height: 24px !important;
        white-space: normal !important;
    }
    .ant-layout-header {
        padding: 0 !important;
    }
    .ant-layout-header menu {
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 20px;
    }
    .logo {
        width: 120px;
        height: 31px;
        /* margin: 16px 495px 15px 40px; */
        margin: 16px 100px 15px 400px;
        float: left;
        background-image: url(../images/common/logo@2x.png);
        background-size: 120px;
        background-repeat: no-repeat;
    }
    [data-theme="dark"] .site-layout-content {
        background: #141414;
    }
    [data-theme="dark"] .logo {
        background: rgba(255, 255, 255, 0.3);
    }
    .rc-footer {
        position: relative;
        clear: both;
        color: rgba(255, 255, 255, 0.4);
        font-size: 14px;
        line-height: 1.5;
        background-color: #000;
    }
    .rc-footer-container {
        //max-width: unset;
        //padding: 60px;
        //margin: 0 auto;
        padding-top: 60px;
    }
    .rc-footer-columns {
        justify-content: space-around;
        display: flex;
        margin: 0 auto;
        width: 80%;
    }
    .rc-footer-columns .footer-logo {
        width: 140px;
        height: 37px;
    }
    .rc-footer-column .title {
        font-size: 16px;

        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        line-height: 48px;
    }
    .rc-footer-column .footer-item-title {
        font-size: 16px;

        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        line-height: 20px;
    }
    .rc-footer-column .footer-item-boder {
        border: 2px solid rgba(51, 125, 255, 1);
        width: 18px;
        margin-top: 14px;
    }
    .rc-footer-column .footer-box {
        margin-top: 10px;
    }
    .rc-footer-item {
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 48px;
    }
    .rc-footer-item img {
        margin-top: 18px;
    }
    .rc-footer-bottom-container {
        max-width: calc(100% - 120px);
        width: 100%;
        text-align: center;
        margin: 0 auto;
        padding: 16px 0;
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 20px;
    }
    .compass section {
        margin-top: 60px;
    }
    .compass .show-appli {
        background: #000000;
        width: 100%;
        height: 100%;
    }
    .ant-modal-footer {
        border: none !important;
    }
    .ant-row {
        width: auto;
        margin: 20px 20px !important;
    }
    .header {
        z-index: 2;
        width: 100%;
        height: 420px !important;
        background: url(../images/andChain/banner.png) !important;
        position: relative;
    }
    .ant-row {
        margin: 20px auto;
    }
    .breadcrumb {
        position: absolute;
        top: 70px;
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65) !important;
        line-height: 17px;
        z-index: 10;
        margin: 15px 40px;
    }
    .compass-tab {
        margin: 0 auto 40px auto;
        text-align: center;
    }
    .compass-tab-fixed {
        position: fixed;
        width: 100%;
        top: 0;
        text-align: center;
        z-index: 9;
        /* margin-left: 20% !important; */
    }
    .header-top {
        position: absolute;
        z-index: 3;
        width: 100%;
        text-align: center;
        top: 145px;
    }
    .header-top div {
        font-size: 48px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        line-height: 67px;
    }
    .header-top p {
        font-size: 24px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 48px;
        margin: 0;
    }
    .box-content .case {
        margin: 55px auto !important;
        display: block !important;
    }
    .adv .ant-row {
        margin: 5px !important;
    }
    .box-content .adv,
    .box-content .appli {
        background: #f6f8ff;
        padding: 0;
    }
    .box-content .yycj,
    .box-content .cpys {
        padding: 50px 0px 35px 0 !important;
    }
    .box-content .des,
    .box-content .fea,
    .box-content .case,
    .box-content .cpys,
    .box-content .yycj {
        /* width: 75%; */
        width: 1070px;
        margin: 50px auto;
    }
    .box-content .des .des-border,
    .box-content .adv .adv-border,
    .box-content .fea .fea-border,
    .box-content .appli .appli-border,
    .box-content .case .case-border {
        border: 2.5px solid #1e2029;
        height: 24px;
        margin-right: 10px;
        float: left;
    }
    .box-content .des .des-title,
    .box-content .adv .adv-title,
    .box-content .fea .fea-title,
    .box-content .appli .appli-title,
    .box-content .case .case-title {
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        float: left;
        font-size:20px;
        line-height:24px;
    }
    .box-content .des .des-content {
        display: inline-block;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(119, 119, 119, 1);
        line-height: 21px;
        margin: 10px 14px 0 14px;
    }
    .box-content .des .des-download {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51, 125, 255, 1);
        line-height: 24px;
    }
    .box-content .des .des-img {
        text-align: center;
        margin-top: 15px;
    }
    .box-content .des .des-img img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
    .box-content .des .des-img-bottom {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(119, 119, 119, 1);
        line-height: 21px;
        margin: 10px 0 25px 0;
    }
    .box-content .adv .adv-top,
    .box-content .fea .fea-top,
    .box-content .appli .appli-top,
    .box-content .case .case-top,
    .box-content .case {
        display: inline-block;
    }
    .box-content .adv .adv-card-box1 {
        float: left;
        margin: 20px 20px 0 10px;
    }
    .box-content .adv .adv-card-box2 {
        margin: 0 38px 0 80px;
    }
    .box-content .fea .fea-list {
        height: 132px;
        /* background:rgba(255,255,255,1); */
        border: 1px solid rgba(240, 242, 245, 1);
        margin: 20px;
        padding: 15px 15px 15px 15px;
    }
    .box-content .fea .fea-list h2 {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        line-height: 21px;
    }
    .box-content .fea .fea-list h3 {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        line-height: 21px;
    }
    .box-content .fea .fea-list p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(119, 119, 119, 1);
        line-height: 20px;
    }
    .box-content .appli .appli-img {
        float: left;
        position: relative;
    }
    .box-content .appli .appli-img img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
    .box-content .appli .less {
        margin: 74px 74px !important;
    }
    .box-content .appli .cha {
        margin: 74px 58px !important;
    }
    .box-content .appli .appi-img-text {
        position: absolute;
        text-align: center;
        margin: 75px 58px;
    }
    .box-content .appli .appi-img-text h2 {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        line-height: 24px;
    }
    .box-content .appli .appi-img-text p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 21px;
        margin-bottom: 0;
    }
    .box-content .appli .appli-title {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
        line-height: 21px;
        display: inline-block;
    }
    .box-content .appli .appli-content {
        margin: 14px 40px 0 188px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(119, 119, 119, 1);
        line-height: 21px;
    }
    .box-content .case .case-content,
    .box-content .des .des-content{
        text-align: justify;
    }
    .box-content .case .case-content {
        margin: 10px 14px;
    }
    .box-content .case .case-content h2 {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        line-height: 20px;
    }
    .box-content .case .case-content p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(119, 119, 119, 1);
        line-height: 20px;
        margin-bottom: 0;
    }
    .compass .yycj-col {
        max-width: 500px;
        height: 180px;
        margin-right: 15px;
        background: #fff;
    }
    .ant-modal-content{
        width: 560px !important;
        /* height: 392px !important; */
    }
    .ant-modal-body{
        padding: 0 !important;
    }
}
