@import "../../assets/css/common";
.commonSolution {
    @include boxSize(100%, auto);
    overflow: hidden;
    position: relative;
    margin-top: -.6rem;
    .showBoxCom {
        .box{
            // img{
            //     display: none;
            // }
            .botContent {
                margin-top: 0;
            }
        }
    }
}
